import React from "react";
import { Table, Input, Button } from "semantic-ui-react";
import "../index.css";

export default function DistribuicaoSemanal() {
  const rows = () => {
    const rows = [];
    for (var i = 0; i < 1; i++) {
      rows.push(
        <Table.Row>
          <Table.Cell>{i + 1}</Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInput" />
          </Table.Cell>
        </Table.Row>
      );
    }
    return rows;
  };

  return (
    <div className="divForm">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Semanas</Table.HeaderCell>
            <Table.HeaderCell>Segunda</Table.HeaderCell>
            <Table.HeaderCell>Terça</Table.HeaderCell>
            <Table.HeaderCell>Quarta</Table.HeaderCell>
            <Table.HeaderCell>Quinta</Table.HeaderCell>
            <Table.HeaderCell>Sexta</Table.HeaderCell>
            <Table.HeaderCell>Sábado</Table.HeaderCell>
            <Table.HeaderCell>Intervalo</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{rows()}</Table.Body>
      </Table>
      <Button primary className="btnIniciar">
        Adicionar Semana
      </Button>
    </div>
  );
}
