import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import { labels } from "./labels/interrogatorioLabels";

export default function Interrogatorio() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [transpiracao, setTranspiracao] = useState(
    acupuntura.interrogatorio.transpiracao
  );
  const [obTranspiracao, setObTranspiracao] = useState(
    acupuntura.interrogatorio.obTranspiracao
  );
  const [sono, setSono] = useState(acupuntura.interrogatorio.sono);
  const [obSono, setObSono] = useState(acupuntura.interrogatorio.obSono);
  const [emocoes, setEmocoes] = useState(acupuntura.interrogatorio.emocoes);
  const [obEmocoes, setObEmocoes] = useState(
    acupuntura.interrogatorio.obEmocoes
  );
  const [alimentacao, setAlimentacao] = useState(
    acupuntura.interrogatorio.alimentacao
  );
  const [obAlimentacao, setObAlimentacao] = useState(
    acupuntura.interrogatorio.obAlimentacao
  );
  const [sabores, setSabores] = useState(acupuntura.interrogatorio.sabores);
  const [obSabores, setObSabores] = useState(
    acupuntura.interrogatorio.obSabores
  );
  const [sede, setSede] = useState(acupuntura.interrogatorio.sede);
  const [obSede, setObSede] = useState(acupuntura.interrogatorio.obSede);
  const [disfuncoes, setDisfuncoes] = useState(
    acupuntura.interrogatorio.disfuncoes
  );
  const [obDisfuncoes, setObDisfuncoes] = useState(
    acupuntura.interrogatorio.obDisfuncoes
  );
  const [excrecoes, setExcrecoes] = useState(
    acupuntura.interrogatorio.excrecoes
  );
  const [obExcrecoes, setObExcrecoes] = useState(
    acupuntura.interrogatorio.obExcrecoes
  );
  const [menstruacao, setMenstruacao] = useState(
    acupuntura.interrogatorio.menstruacao
  );
  const [obMenstruacao, setObMenstruacao] = useState(
    acupuntura.interrogatorio.obMenstruacao
  );

  useEffect(() => {
    dispatch(
      actions.setInterrogatorio({
        transpiracao: transpiracao,
        obTranspiracao: obTranspiracao,
        sono: sono,
        obSono: obSono,
        emocoes: emocoes,
        obEmocoes: obEmocoes,
        alimentacao: alimentacao,
        obAlimentacao: obAlimentacao,
        sabores: sabores,
        obSabores: obSabores,
        sede: sede,
        obSede: obSede,
        disfuncoes: disfuncoes,
        obDisfuncoes: obDisfuncoes,
        excrecoes: excrecoes,
        obExcrecoes: obExcrecoes,
        menstruacao: menstruacao,
        obMenstruacao: obMenstruacao,
      })
    );
  }, [
    dispatch,
    transpiracao,
    obTranspiracao,
    sono,
    obSono,
    emocoes,
    obEmocoes,
    alimentacao,
    obAlimentacao,
    sabores,
    obSabores,
    sede,
    obSede,
    disfuncoes,
    obDisfuncoes,
    excrecoes,
    obExcrecoes,
    menstruacao,
    obMenstruacao,
  ]);

  const checkboxes = (nomeEstado, estado, setEstado) => {
    let labelArray = labels[nomeEstado];
    return labelArray.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={estado.includes(la.value)}
                  onChange={(e, data) => {
                    if (estado.includes(data.value)) {
                      setEstado(estado.filter((e) => e !== data.value));
                    } else {
                      setEstado([...estado, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  return (
    <React.Fragment>
      <Form.Field>
        <label>Transpiração</label>
        <Grid>
          <Grid.Row>
            {checkboxes("transpiracao", transpiracao, setTranspiracao)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obTranspiracao}
          onChange={(e) => setObTranspiracao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sono</label>
        <Grid>
          <Grid.Row>{checkboxes("sono", sono, setSono)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obSono}
          onChange={(e) => setObSono(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Emoções</label>
        <Grid>
          <Grid.Row>{checkboxes("emocoes", emocoes, setEmocoes)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obEmocoes}
          onChange={(e) => setObEmocoes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Alimentação</label>
        <Grid>
          <Grid.Row>
            {checkboxes("alimentacao", alimentacao, setAlimentacao)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obAlimentacao}
          onChange={(e) => setObAlimentacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sabores</label>
        <Grid>
          <Grid.Row>{checkboxes("sabores", sabores, setSabores)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obSabores}
          onChange={(e) => setObSabores(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sede</label>
        <Grid>
          <Grid.Row>{checkboxes("sede", sede, setSede)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obSede}
          onChange={(e) => setObSede(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Disfunções Gastrointestinais</label>
        <Grid>
          <Grid.Row>
            {checkboxes("disfuncoes", disfuncoes, setDisfuncoes)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obDisfuncoes}
          onChange={(e) => setObDisfuncoes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Excreções</label>
        <Grid>
          <Grid.Row>
            {checkboxes("excrecoes", excrecoes, setExcrecoes)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obExcrecoes}
          onChange={(e) => setObExcrecoes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Menstruação</label>
        <Grid>
          <Grid.Row>
            {checkboxes("menstruacao", menstruacao, setMenstruacao)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obMenstruacao}
          onChange={(e) => setObMenstruacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
