import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import { labels } from "./labels/algiasLabels";

export default function Algias() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [coluna, setColuna] = useState(acupuntura.algias.coluna);
  const [obColuna, setObColuna] = useState(acupuntura.algias.obColuna);
  const [doresMusculares, setDoresMusculares] = useState(
    acupuntura.algias.doresMusculares
  );
  const [doresArticulares, setDoresArticulares] = useState(
    acupuntura.algias.doresArticulares
  );
  const [obdoresArticulares, setObDoresArticulares] = useState(
    acupuntura.algias.obdoresArticulares
  );
  const [abdomen, setAbdomen] = useState(acupuntura.algias.abdomen);
  const [obAbdomen, setObAbdomen] = useState(acupuntura.algias.obAbdomen);
  const [torax, setTorax] = useState(acupuntura.algias.torax);
  const [obTorax, setObTorax] = useState(acupuntura.algias.obTorax);
  const [doresCabeca, setDoresCabeca] = useState(acupuntura.algias.doresCabeca);
  const [obDoresCabeca, setObDoresCabeca] = useState(
    acupuntura.algias.obDoresCabeca
  );

  const checkboxes = (nomeEstado, estado, setEstado) => {
    let labelArray = labels[nomeEstado];
    return labelArray.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={estado.includes(la.value)}
                  onChange={(e, data) => {
                    if (estado.includes(data.value)) {
                      setEstado(estado.filter((e) => e !== data.value));
                    } else {
                      setEstado([...estado, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  useEffect(() => {
    dispatch(
      actions.setAlgias({
        coluna: coluna,
        obColuna: obColuna,
        doresMusculares: doresMusculares,
        doresArticulares: doresArticulares,
        obdoresArticulares: obdoresArticulares,
        abdomen: abdomen,
        obAbdomen: obAbdomen,
        torax: torax,
        obTorax: obTorax,
        doresCabeca: doresCabeca,
        obDoresCabeca: obDoresCabeca,
      })
    );
  }, [
    dispatch,
    coluna,
    obColuna,
    doresMusculares,
    doresArticulares,
    obdoresArticulares,
    abdomen,
    obAbdomen,
    torax,
    obTorax,
    doresCabeca,
    obDoresCabeca,
  ]);

  return (
    <React.Fragment>
      <Form.Field>
        <label>Coluna</label>
        <Grid>
          <Grid.Row>{checkboxes("coluna", coluna, setColuna)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obColuna}
          onChange={(e) => setObColuna(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Algias periféricas em membros superiores e inferiores</label>
        <label>Dores musculares</label>
        <Grid>
          <Grid.Row>
            {checkboxes("doresMusculares", doresMusculares, setDoresMusculares)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Dores articulares (Síndorme Bi) </label>
        <Grid>
          <Grid.Row>
            {checkboxes(
              "doresArticulares",
              doresArticulares,
              setDoresArticulares
            )}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obdoresArticulares}
          onChange={(e) => setObDoresArticulares(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Abdomen</label>
        <Grid>
          <Grid.Row>{checkboxes("abdomen", abdomen, setAbdomen)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obAbdomen}
          onChange={(e) => setObAbdomen(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Tórax</label>
        <Grid>
          <Grid.Row>{checkboxes("torax", torax, setTorax)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obTorax}
          onChange={(e) => setObTorax(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Dores de Cabeça</label>
        <Grid>
          <Grid.Row>
            {checkboxes("doresCabeca", doresCabeca, setDoresCabeca)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={obDoresCabeca}
          onChange={(e) => setObDoresCabeca(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
