import React, { useState, useEffect } from "react";
import { LinguaElement } from "../styles";
import { Form, TextArea } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import Lingua1 from "../../../assets/images/Lingua1.png";
import Lingua2 from "../../../assets/images/Lingua2.png";
import Lingua3 from "../../../assets/images/Lingua3.png";
import Lingua4 from "../../../assets/images/Lingua4.png";
import Lingua5 from "../../../assets/images/Lingua5.png";
import Lingua6 from "../../../assets/images/Lingua6.png";

export default function Lingua() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const [opacity1, setOpacity1] = useState(acupuntura.lingua.opacity1);
  const [opacity2, setOpacity2] = useState(acupuntura.lingua.opacity2);
  const [opacity3, setOpacity3] = useState(acupuntura.lingua.opacity3);
  const [opacity4, setOpacity4] = useState(acupuntura.lingua.opacity4);
  const [opacity5, setOpacity5] = useState(acupuntura.lingua.opacity5);
  const [opacity6, setOpacity6] = useState(acupuntura.lingua.opacity6);
  const [lingua1, setLingua1] = useState(acupuntura.lingua.lingua1);
  const [lingua2, setLingua2] = useState(acupuntura.lingua.lingua2);
  const [lingua3, setLingua3] = useState(acupuntura.lingua.lingua3);
  const [lingua4, setLingua4] = useState(acupuntura.lingua.lingua4);
  const [lingua5, setLingua5] = useState(acupuntura.lingua.lingua5);
  const [lingua6, setLingua6] = useState(acupuntura.lingua.lingua6);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setLingua({
        opacity1: opacity1,
        opacity2: opacity2,
        opacity3: opacity3,
        opacity4: opacity4,
        opacity5: opacity5,
        opacity6: opacity6,
        lingua1: lingua1,
        lingua2: lingua2,
        lingua3: lingua3,
        lingua4: lingua4,
        lingua5: lingua5,
        lingua6: lingua6,
      })
    );
  }, [
    dispatch,
    opacity1,
    opacity2,
    opacity3,
    opacity4,
    opacity5,
    opacity6,
    lingua1,
    lingua2,
    lingua3,
    lingua4,
    lingua5,
    lingua6,
  ]);

  function setOpacity(e) {
    switch (e) {
      case 1:
        setOpacity1(!opacity1);
        break;
      case 2:
        setOpacity2(!opacity2);
        break;
      case 3:
        setOpacity3(!opacity3);
        break;
      case 4:
        setOpacity4(!opacity4);
        break;
      case 5:
        setOpacity5(!opacity5);
        break;
      case 6:
        setOpacity6(!opacity6);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div>
        <LinguaElement
          size={"294px"}
          src={Lingua1}
          top="31px"
          left="212px"
          opacity={opacity1 ? "100%" : "60%"}
          onClick={() => setOpacity(1)}
        />
        <LinguaElement
          size={"294px"}
          src={Lingua2}
          top="30px"
          left="-3px"
          opacity={opacity2 ? "100%" : "60%"}
          onClick={() => setOpacity(2)}
        />
        <LinguaElement
          size={"204px"}
          src={Lingua3}
          top="75px"
          left="66px"
          opacity={opacity3 ? "100%" : "60%"}
          onClick={() => setOpacity(3)}
        />
        <LinguaElement
          size={"100px"}
          src={Lingua4}
          top="8px"
          left="8px"
          opacity={opacity4 ? "100%" : "60%"}
          onClick={() => setOpacity(4)}
        />
        <LinguaElement
          size={"102px"}
          src={Lingua5}
          top="230px"
          left="56px"
          opacity={opacity5 ? "100%" : "60%"}
          onClick={() => setOpacity(5)}
        />
        <LinguaElement
          size={"96px"}
          src={Lingua6}
          top="281px"
          left="63px"
          opacity={opacity6 ? "100%" : "60%"}
          onClick={() => setOpacity(6)}
        />
      </div>
      <div style={{ paddingTop: "400px" }}>
        <Form.Field>
          <label>Língua 1 - Rim/Bexiga</label>
          <TextArea
            value={lingua1}
            onChange={(e) => setLingua1(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Língua 2 - Vesícula Biliar/Figado</label>
          <TextArea
            value={lingua2}
            onChange={(e) => setLingua2(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Língua 3 - Figado/Vesícula Biliar</label>
          <TextArea
            value={lingua3}
            onChange={(e) => setLingua3(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Língua 4 - Baço/Estômago</label>
          <TextArea
            value={lingua4}
            onChange={(e) => setLingua4(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Língua 5 - Pulmão/Int. Grosso</label>
          <TextArea
            value={lingua5}
            onChange={(e) => setLingua5(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Língua 6 - Coração/Int. Delgado</label>
          <TextArea
            value={lingua6}
            onChange={(e) => setLingua6(e.target.value)}
          />
        </Form.Field>
      </div>
    </>
  );
}
