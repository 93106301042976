import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea } from "semantic-ui-react";

export default function Sessao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const [queixaPrincipal, setQueixaPrincipal] = useState(
    acupuntura.sessao.queixaPrincipal
  );
  const [queixaSecundaria, setQueixaSecundaria] = useState(
    acupuntura.sessao.queixaSecundaria
  );
  const [observacao, setObservacao] = useState(acupuntura.sessao.observacao);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setSessao({
        queixaPrincipal: queixaPrincipal,
        queixaSecundaria: queixaSecundaria,
        observacao: observacao,
      })
    );
  }, [dispatch, queixaPrincipal, queixaSecundaria, observacao]);

  return (
    <React.Fragment>
      <Form.Field>
        <label>Queixa Principal</label>
        <TextArea
          value={queixaPrincipal}
          onChange={(e) => setQueixaPrincipal(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Queixa Secundária</label>
        <TextArea
          value={queixaSecundaria}
          onChange={(e) => setQueixaSecundaria(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
