import React, { useEffect, useState } from "react";
import { Form, TextArea } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";

export default function Interrogatorio() {
  const avEnergetica = useSelector((state) => state.avEnergetica);
  const [gravida, setGravida] = useState(avEnergetica.interrogatorio.gravida);
  const [esperaArte, setEsperaArte] = useState(
    avEnergetica.interrogatorio.esperaArte
  );
  const [praticouArtes, setPraticouArtes] = useState(
    avEnergetica.interrogatorio.praticouArtes
  );
  const [movimentos, setMovimentos] = useState(
    avEnergetica.interrogatorio.movimentos
  );
  const [cirurgia, setCirurgia] = useState(
    avEnergetica.interrogatorio.cirurgia
  );
  const [doencas, setDoencas] = useState(avEnergetica.interrogatorio.doencas);
  const [antecedentes, setAntecedentes] = useState(
    avEnergetica.interrogatorio.antecedentes
  );
  const [pai, setPai] = useState(avEnergetica.interrogatorio.pai);
  const [mae, setMae] = useState(avEnergetica.interrogatorio.mae);
  const [medicacao, setMedicacao] = useState(
    avEnergetica.interrogatorio.medicacao
  );
  const [lesao, setLesao] = useState(avEnergetica.interrogatorio.lesao);
  const [dormeAcorda, setDormeAcorda] = useState(
    avEnergetica.interrogatorio.dormeAcorda
  );
  const [sono, setSono] = useState(avEnergetica.interrogatorio.sono);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setInterrogatorio({
        gravida: gravida,
        esperaArte: esperaArte,
        praticouArtes: praticouArtes,
        movimentos: movimentos,
        cirurgia: cirurgia,
        doencas: doencas,
        antecedentes: antecedentes,
        pai: pai,
        mae: mae,
        medicacao: medicacao,
        lesao: lesao,
        dormeAcorda: dormeAcorda,
        sono: sono,
      })
    );
  }, [
    dispatch,
    gravida,
    esperaArte,
    praticouArtes,
    movimentos,
    cirurgia,
    doencas,
    antecedentes,
    pai,
    mae,
    medicacao,
    lesao,
    dormeAcorda,
    sono,
  ]);

  return (
    <>
      <Form.Group inline>
        <label>Grávida</label>
        <Form.Radio
          label="Sim"
          value={true}
          checked={gravida === true}
          onChange={() => setGravida(true)}
        />
        <Form.Radio
          label="Não"
          value={false}
          checked={gravida === false}
          onChange={() => setGravida(false)}
        />
      </Form.Group>
      <Form.Field>
        <label>O que espera com a prática da arte marcial?</label>
        <TextArea
          value={esperaArte}
          onChange={(e) => setEsperaArte(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Já praticou outras artes marciais?</label>
        <TextArea
          value={praticouArtes}
          onChange={(e) => setPraticouArtes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Tem alguma limitação de movimentos?</label>
        <TextArea
          value={movimentos}
          onChange={(e) => setMovimentos(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Tem cirurgia?</label>
        <TextArea
          value={cirurgia}
          onChange={(e) => setCirurgia(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Doenças?</label>
        <TextArea
          value={doencas}
          onChange={(e) => setDoencas(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Antecedentes de doenças?</label>
        <TextArea
          value={antecedentes}
          onChange={(e) => setAntecedentes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Pai</label>
        <TextArea
          value={pai}
          onChange={(e) => setPai(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Mãe</label>
        <TextArea
          value={mae}
          onChange={(e) => setMae(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Toma alguma medicação?</label>
        <TextArea
          value={medicacao}
          onChange={(e) => setMedicacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Tem alguma lesão?</label>
        <TextArea
          value={lesao}
          onChange={(e) => setLesao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Que horas dorme e acorda?</label>
        <TextArea
          value={dormeAcorda}
          onChange={(e) => setDormeAcorda(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Como é o sono?</label>
        <TextArea
          value={sono}
          onChange={(e) => setSono(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </>
  );
}
