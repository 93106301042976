import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import * as labels from "./labels/compleicaoLabels";

export default function Compleicao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const [geral, setGeral] = useState(acupuntura.compleicao.geral);
  const [aspectoGeral, setAspectoGeral] = useState(
    acupuntura.compleicao.aspectoGeral
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setCompleicao({
        geral: geral,
        aspectoGeral: aspectoGeral,
      })
    );
  }, [dispatch, geral, aspectoGeral]);

  const checkboxes = () => {
    return labels.label.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={geral.includes(la.value)}
                  onChange={(e, data) => {
                    if (geral.includes(data.value)) {
                      setGeral(geral.filter((e) => e !== data.value));
                    } else {
                      setGeral([...geral, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  return (
    <React.Fragment>
      <Form.Field>
        <label>Geral</label>
        <Grid>
          <Grid.Row>{checkboxes()}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>
          Aspecto geral do corpo (forma, postura, locomoção, astenia)
        </label>
        <TextArea
          value={aspectoGeral}
          onChange={(e) => setAspectoGeral(e.target.value)}
        />
      </Form.Field>
    </React.Fragment>
  );
}
