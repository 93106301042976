import React from "react";
import { Tab } from "semantic-ui-react";
import Sidebar from "../../assets/Sidebar";
import ProgramaTreinamento from "./Forms/ProgramaTreinamento";
import ComposicaoCorporal from "./Forms/ComposicaoCorporal";
import Perimetria from "./Forms/Perimetria";
import DistribuicaoSemanal from "./Forms/DistribuicaoSemanal";
import Exercicios from "./Forms/Exercicios";

const panes = [
  {
    menuItem: "Programa de Treinamento",
    render: () => <ProgramaTreinamento />,
  },
  {
    menuItem: "Composição Corporal",
    render: () => <ComposicaoCorporal />,
  },
  {
    menuItem: "Perimetria",
    render: () => <Perimetria />,
  },
  {
    menuItem: "Distribuição Semanal",
    render: () => <DistribuicaoSemanal />,
  },
  {
    menuItem: "Exercícios",
    render: () => <Exercicios />,
  },
];

export default function Personal() {
  return (
    <React.Fragment>
      <Sidebar />
      <div className="divContainer">
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </React.Fragment>
  );
}
