import React from "react";
import { Form } from "semantic-ui-react";
import "../index.css";

export default function Perimetria() {
  return (
    <div className="divForm">
      <Form>
        <Form.Field>
          <label>Peso</label>
          <input placeholder="Peso" />
        </Form.Field>
        <Form.Field>
          <label>Altura</label>
          <input placeholder="Altura" />
        </Form.Field>
        <Form.Field>
          <label>Braço Direito</label>
          <input placeholder="Braço Direito" />
        </Form.Field>
        <Form.Field>
          <label>Braço Esquerdo</label>
          <input placeholder="Braço Esquerdo" />
        </Form.Field>
        <Form.Field>
          <label>Tórax</label>
          <input placeholder="Tórax" />
        </Form.Field>
        <Form.Field>
          <label>Cintura</label>
          <input placeholder="Cintura" />
        </Form.Field>
        <Form.Field>
          <label>Quadril</label>
          <input placeholder="Quadril" />
        </Form.Field>
        <Form.Field>
          <label>Coxa Direita</label>
          <input placeholder="Coxa Direita" />
        </Form.Field>
        <Form.Field>
          <label>Coxa Esquerda</label>
          <input placeholder="Coxa Esquerda" />
        </Form.Field>
        <Form.Field>
          <label>Panturrilha Direita</label>
          <input placeholder="Panturrilha Direita" />
        </Form.Field>
        <Form.Field>
          <label>Paturrilha Esquerda</label>
          <input placeholder="Paturrilha Esquerda" />
        </Form.Field>
        <Form.Field>
          <label>Pescoço</label>
          <input placeholder="Pescoço" />
        </Form.Field>
      </Form>
    </div>
  );
}
