export const labels = {
  transpiracao: [
    [
      { key: 1, text: "Sua pouco (normal)", value: 1 },
      {
        key: 2,
        text: "Não sua (QI perverso frio, def. Yang QI, def. Xue)",
        value: 2,
      },
      { key: 3, text: "Sua muito (QI perverso (vento/calor)", value: 3 },
      { key: 4, text: "Suor na cabeça (def. yin QI, calor no E)", value: 4 },
    ],
    [
      { key: 5, text: "Suor frio (def.Yang QI)", value: 5 },
      { key: 6, text: "Suor nas mãos e pés (debilidade dos R)", value: 6 },
      { key: 7, text: "Suor noturno (def. Yin QI, calor interno)", value: 7 },
    ],
  ],
  sono: [
    [
      { key: 1, text: "Dorme bem", value: 1 },
      { key: 2, text: "Insônia", value: 2 },
      { key: 3, text: "Muito sono", value: 3 },
    ],
    [
      { key: 4, text: "Pouco sono", value: 4 },
      { key: 5, text: "Sonhos", value: 5 },
    ],
  ],
  emocoes: [
    [
      { key: 1, text: "Medo (R/B)", value: 1 },
      { key: 2, text: "Preocupação/Pensamento excessivo (BP/E)", value: 2 },
      { key: 3, text: "Raiva/Irritabilidade (F/VB)", value: 3 },
    ],
    [
      { key: 4, text: "Alegria (C/ID)", value: 4 },
      { key: 5, text: "Tristeza (P/IG)", value: 5 },
    ],
  ],
  alimentacao: [
    [
      { key: 1, text: "Come muito (calor no E)", value: 1 },
      {
        key: 2,
        text:
          "Come pouco (def.QI do BP, calor/umidade em BP, retenção de alimentso no E)",
        value: 2,
      },
      { key: 3, text: "Sem apetite (vazio em QI em BP e E)", value: 3 },
    ],
    [
      {
        key: 4,
        text: "Prefere alimentos quentes (sindrome de frio)",
        value: 4,
      },
      { key: 5, text: "Prefere alimentos frios (sindrome de calor)", value: 5 },
    ],
  ],
  sabores: [
    [
      { key: 1, text: "Salgado (def. QI do R)", value: 1 },
      { key: 2, text: "Doce (def. QI do BP)", value: 2 },
      { key: 3, text: "Picante (def. QI do P)", value: 3 },
    ],
    [
      { key: 4, text: "Amargo (def. QI do C)", value: 4 },
      { key: 5, text: "Azedo/Ácido (estagnação de QI do F)", value: 5 },
    ],
  ],
  sede: [
    [
      {
        key: 1,
        text: "Sem sede (plenitude e QI perverso frio / umidade em BP)",
        value: 1,
      },
      {
        key: 2,
        text:
          "Pouca sede (def. Yang QI, estagnação em meridianos, acúmulo de frio no E)",
        value: 2,
      },
    ],
    [
      { key: 3, text: "Muita sede (calor interno)", value: 3 },
      { key: 4, text: "Preferência por água gelada (calor interno)", value: 4 },
    ],
  ],
  disfuncoes: [
    [
      {
        key: 1,
        text: "Halitose (retenção de alimentos no E)",
        value: 1,
      },
      {
        key: 2,
        text: "Náuseas (enjôos)(QI perverso calor-umidade em E)",
        value: 2,
      },
      { key: 3, text: "Constipação (QI perverso calor em IG)", value: 3 },
    ],
    [
      { key: 4, text: "Azia (QI no E em ascensão)", value: 4 },
      { key: 5, text: "Gastrite (QI perverso calor em E)", value: 5 },
      {
        key: 6,
        text: "Diarréia (QI perverso calor ou frio/umidade)",
        value: 6,
      },
    ],
  ],
  excrecoes: [
    [
      {
        key: 1,
        text: "Diarréia seguida de alívio (def. Yang QI do R)",
        value: 1,
      },
      {
        key: 2,
        text: "Diarréia com alimentos não digeridos (def. QI de BP)",
        value: 2,
      },
      { key: 3, text: "Fezes retidas (excesso de Yin QI do R)", value: 3 },
      {
        key: 4,
        text: "Fezes finas com água (QI perverso umidade/frio em BP e E)",
        value: 4,
      },
      {
        key: 5,
        text: "Fezes com muco (QI perverso umidade/frio no IG)",
        value: 5,
      },
      { key: 6, text: "Fezes secas (calor interno/def. Yin QI)", value: 6 },
      { key: 7, text: "Fezes com sangue (QI perverso calor no IG)", value: 7 },
      { key: 8, text: "Escura (QI perverso calor em R e B)", value: 8 },
    ],
    [
      {
        key: 9,
        text: "Clara (QI perverso frio)",
        value: 9,
      },
      {
        key: 10,
        text: "Muita (def. Yang QI do R, QI perv. calor/umidade em B)",
        value: 10,
      },
      { key: 11, text: "Pouca (sindrome de calor)", value: 11 },
      {
        key: 12,
        text: "Incontinência/solta (def. QI do R, afundamento do QI do BP)",
        value: 12,
      },
      {
        key: 13,
        text: "Com sangue/retenção (QI perverso calor/umidade em B)",
        value: 13,
      },
      {
        key: 14,
        text: "Enurese noturna (def. de QI do R, disf. de B)",
        value: 14,
      },
      { key: 15, text: "Noctúria (def. Yang QI do R)", value: 15 },
    ],
  ],
  menstruacao: [
    [
      {
        key: 1,
        text:
          "Ciclo prolongado e escasso com coloração clara (def. de QI e Xue, QI perverso frio)",
        value: 1,
      },
      {
        key: 2,
        text:
          "Menorragia/ciclo diminuido com muito sangue e coloração escura (estagnação de sangue, calor no sangue, def. QI de BP, hiperatividade do Yang QI do F)",
        value: 2,
      },
      {
        key: 3,
        text: "Metrorragia/Irregular (estagnação e QI perverso calor em Xue)",
        value: 3,
      },
      {
        key: 4,
        text: "Dismenorréia/cólicas (estagnação de QI e Xue do F)",
        value: 4,
      },
    ],
    [
      { key: 5, text: "Amenorréia (def. QI + Xue, def. Xue do F)", value: 5 },
      {
        key: 6,
        text:
          "Leucorréia com corrimento branco, e aquoso (def. de Yang QI do BP e do R)",
        value: 6,
      },
      {
        key: 7,
        text:
          "Leucorréia espessa com cor amarela e odor desagradável (QI perverso umidade-calor em TA inferior)",
        value: 7,
      },
    ],
  ],
};
