import React, { useState } from "react";
import "./main.css";
import {
  Segment,
  Form,
  Button,
  Message,
  Loader,
  Image,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { login } from "../../services/auth";
import Logo from "../../assets/images/BackgroundHome.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/Home" } };

  function signin(e) {
    setIsLoading(true);
    e.preventDefault();
    if (!email || !pwd) {
      toast.error("Preencha e-mail e senha para continuar!");
      setIsLoading(false);
    } else {
      axios
        .post(process.env.REACT_APP_API_ENDPOINT + `login`, { email, pwd })
        .then((res) => {
          const { token } = res.data;
          const { nome, email, acessos } = res.data.result;
          login(token);
          localStorage.setItem("@wd_username", nome);
          localStorage.setItem("@wd_email", email);
          localStorage.setItem("@wd_acessos", acessos);
          setIsLoading(false);
          history.push("/home");
        })
        .catch(function (error) {
          setIsLoading(false);
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(
              "Houve um problema com o login, verifique suas credenciais."
            );
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            toast.error(
              "Houve um problema com o servidor, tente novamente mais tarde."
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            toast.error(
              "Houve um problema com o login, verifique suas credenciais."
            );
          }
        });
    }
  }

  return (
    <div className="divLogin">
      <Image src={Logo} className="logoWudang" />
      <Segment className="segmentLogin">
        <Form onSubmit={signin}>
          <Form.Field>
            <label>E-mail</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="E-mail"
            />
          </Form.Field>
          <Form.Field>
            <label>Senha</label>
            <input
              value={pwd}
              type="password"
              onChange={(e) => setPwd(e.target.value)}
              placeholder="Senha"
            />
          </Form.Field>
          <Button
            primary
            type="submit"
            style={{ width: "100%" }}
            disabled={isLoading}
          >
            Entrar {isLoading ? <Loader active inline size="mini" /> : null}
          </Button>
        </Form>
      </Segment>
      <ToastContainer position="bottom-right" />
    </div>
  );
}
