import styled from "styled-components";

export const Element = styled.img`
  position: absolute;
  height: ${(props) => props.size};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;

export const SetasCurvas = styled.img`
  position: absolute;
  height: 30px;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: scaleX(-1) ${(props) => props.rotation};
`;

export const SetasRetas = styled.img`
  position: absolute;
  width: 150px;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: ${(props) => props.rotation};
`;

export const LinguaElement = styled.img`
  position: absolute;
  height: ${(props) => props.size};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  opacity: ${(props) => props.opacity};
`;
