import React, { useState } from "react";
import { Form, Button, Message, Loader, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const optionsAcessos = [
  { key: "Agenda", text: "Agenda", value: "Agenda" },
  { key: "Relatórios", text: "Relatórios", value: "Relatórios" },
  {
    key: "Gerenciamento de Usuários",
    text: "Gerenciamento de Usuários",
    value: "Gerenciamento de Usuários",
  },
  {
    key: "Conteúdo Educacional",
    text: "Conteúdo Educacional",
    value: "Conteúdo Educacional",
  },
  {
    key: "Gerenciamento de Clientes",
    text: "Gerenciamento de Clientes",
    value: "Gerenciamento de Clientes",
  },
  { key: "Acupuntura", text: "Acupuntura", value: "Acupuntura" },
  {
    key: "Avaliação Energética",
    text: "Avaliação Energética",
    value: "Avaliação Energética",
  },
];

export default function CadastrarClientes({ usuario }) {
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [acessos, setAcessos] = useState("");

  function sendForm() {
    if (
      nome === "" ||
      email === "" ||
      senha === "" ||
      confirmarSenha === "" ||
      acessos === ""
    ) {
      toast.warning("Todos os campos são obrigatórios");
    } else if (senha !== confirmarSenha) {
      toast.warning("Campo Senha e Confirmar Senha não conferem");
    } else {
      const id = usuario.id;
      setLoading(true);
      const ac = configAcessos(acessos);
      if (isEdit) {
        axios
          .put(process.env.REACT_APP_API_ENDPOINT + `Usuario`, {
            id: id,
            nome: nome,
            email: email,
            senha: senha,
            acessos: ac,
          })
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              toast.success("Usuário editado com sucesso");
              cleanForm();
            } else {
              toast.error("Erro ao editar Usuário");
            }
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_ENDPOINT + `Usuario`, {
            id: id,
            nome: nome,
            email: email,
            senha: senha,
            acessos: ac,
          })
          .then((res) => {
            setLoading(false);
            if (!res.error) {
              toast.success("Usuário criado com sucesso");
              cleanForm();
            } else {
              toast.error("Erro ao criar Usuário");
            }
          });
      }
    }
  }

  function cleanForm() {
    setIsEdit(false);
    setNome("");
    setEmail("");
    setSenha("");
    setConfirmarSenha("");
    setAcessos("");
  }

  useEffect(() => {
    if (usuario.id) {
      setIsEdit(true);
      setNome(usuario.nome);
      setEmail(usuario.email);
      setSenha(usuario.senha);
      setConfirmarSenha(usuario.senha);
      settingAcessos(usuario.acessos);
    }
  }, [usuario]);

  function configAcessos(ace) {
    let ac = "[";
    if (ace.includes("Agenda")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Gerenciamento de Clientes")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Relatórios")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Conteúdo Educacional")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Avaliação Energética")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Acupuntura")) ac += "1,";
    else ac += "0,";
    if (ace.includes("Gerenciamento de Usuários")) ac += "1]";
    else ac += "0]";
    return ac;
  }

  function settingAcessos(ace) {
    let ac = [];
    if (ace[1] === "1") ac.push("Agenda");
    if (ace[3] === "1") ac.push("Gerenciamento de Clientes");
    if (ace[5] === "1") ac.push("Relatórios");
    if (ace[7] === "1") ac.push("Conteúdo Educacional");
    if (ace[9] === "1") ac.push("Avaliação Energética");
    if (ace[11] === "1") ac.push("Acupuntura");
    if (ace[13] === "1") ac.push("Gerenciamento de Usuários");
    setAcessos(ac);
  }

  return (
    <React.Fragment>
      <Message
        icon="add user"
        header="Cadastrar Usuários"
        //content="Get the best news in your e-mail every day."
      />
      <Form>
        <Form.Field>
          <label>Nome</label>
          <input
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Senha</label>
          <input
            type="password"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Confirmar senha</label>
          <input
            type="password"
            placeholder="Senha"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Acessos</label>
          <Dropdown
            placeholder="Acessos"
            fluid
            multiple
            selection
            options={optionsAcessos}
            value={acessos}
            onChange={(e, { value }) => setAcessos(value)}
          />
        </Form.Field>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            className="btnSelected"
            onClick={sendForm}
            disabled={loading}
          >
            Salvar <Loader active={loading} inline size="mini" />
          </Button>
        </div>
      </Form>
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}
