const initialState = {
  duracao: "",
  isEdit: false,
  fisico: {
    peso: "",
    altura: "",
    pressaoArterial: "",
    transpiracao: "",
    dor: "",
    fezes: "",
    urina: "",
    audicao: "",
    olfato: "",
    visao: "",
    vidaSexual: "",
    menstruacao: "",
    ejaculacao: "",
    vidaEmocional: "",
    gostoLingua: "",
  },
  lajin: {
    maoJoelho: "",
    cabecaJoelho: "",
    cabecaCanela: "",
    cabecaPe: "",
    narizJoelho: "",
    narizCanela: "",
    narizPe: "",
    queixoJoelho: "",
    queixoCanela: "",
    queixoPe: "",
    assinale: "",
    assinaleBloqueio: "",
    assinale2: "",
    tempo: "",
    angulo: "",
    rotacaoEsquerda: "",
    rotacaoDireita: "",
    assinale3: "",
    postura: "",
    posicaoJoelhos: "",
  },
  alimentacao: {
    alimentacao: "",
    sAlimentacao: "",
    comer: "",
    sComer: "",
    comeImportante: "",
    sComeImportante: "",
  },
  interrogatorio: {
    gravida: "",
    esperaArte: "",
    praticouArtes: "",
    movimentos: "",
    cirurgia: "",
    doencas: "",
    antecedentes: "",
    pai: "",
    mae: "",
    medicacao: "",
    lesao: "",
    dormeAcorda: "",
    sono: "",
  },
};

const setFisico = (fisicoObj) => {
  return {
    type: "SET_FISICO",
    payload: fisicoObj,
  };
};

const setLajin = (lajinObj) => {
  return {
    type: "SET_LAJIN",
    payload: lajinObj,
  };
};

const setAlimentacao = (alimentacaoObj) => {
  return {
    type: "SET_ALIMENTACAO",
    payload: alimentacaoObj,
  };
};

const setInterrogatorio = (interrogatorioObj) => {
  return {
    type: "SET_INTERROGATORIO",
    payload: interrogatorioObj,
  };
};

const clearStateAvaliacao = () => {
  return {
    type: "CLEAR_STATE_AVALIACAO",
    payload: initialState,
  };
};

const setAvEnergetica = (avEnergeticaObj) => {
  return {
    type: "SET_AVENERGETICA",
    payload: avEnergeticaObj,
  };
};

export default {
  setFisico,
  setLajin,
  setAlimentacao,
  setInterrogatorio,
  clearStateAvaliacao,
  setAvEnergetica,
};
