import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Modal,
  Form,
  TextArea,
  Message,
  Button,
  Icon,
  Grid,
  Header,
  Popup,
  Loader,
} from "semantic-ui-react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const optionsSala = [
  { key: "Sala 1", value: "Sala 1", text: "Sala 1" },
  { key: "Sala 2", value: "Sala 2", text: "Sala 2" },
];

export default function ModalEvent(props) {
  const {
    modalEventOpen,
    modalEventClose,
    deleteEvent,
    eventSelected,
    editEvent,
    isLoading,
  } = props;
  const [titleEvent, setTitleEvent] = useState(eventSelected.title);
  const [startDate, setStartDate] = useState(eventSelected.start);
  const [endDate, setEndDate] = useState(eventSelected.end);
  const [description, setDescription] = useState(eventSelected.description);
  const [sala, setSala] = useState(eventSelected.sala);
  const [error, setError] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  function closeModalConfirm() {
    setModalConfirm(false);
  }

  function deleteEventConfirm() {
    setModalConfirm(false);
    deleteEvent(eventSelected.id);
  }

  function salvarEdicao() {
    const eventEdited = {
      id: eventSelected.id,
      dataComeco: startDate,
      dataFinal: endDate,
      descricao: description,
      sala: sala,
      nome: titleEvent,
    };
    editEvent(eventEdited);
  }

  return (
    <Modal open={modalEventOpen} onClose={modalEventClose}>
      <Modal.Header>
        <Grid fluid>
          <Grid.Row>
            <Grid.Column width={8}>Evento</Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Popup
                content="Clique aqui para deletar esse agendamento"
                trigger={
                  <Button
                    color="red"
                    icon
                    onClick={() => setModalConfirm(true)}
                    disabled={isLoading}
                  >
                    <Icon name="trash alternate" />
                  </Button>
                }
              />
              <Modal
                basic
                size="small"
                open={modalConfirm}
                onClose={closeModalConfirm}
              >
                <Header
                  icon="archive"
                  content="Deseja realmente deletar esse agendamento?"
                />
                <Modal.Content>
                  <p>Ao deletar esse evento, ele não poderá ser recuperado.</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    basic
                    color="red"
                    inverted
                    onClick={closeModalConfirm}
                  >
                    <Icon name="remove" /> Não
                  </Button>
                  <Button color="green" inverted onClick={deleteEventConfirm}>
                    <Icon name="checkmark" /> Sim
                  </Button>
                </Modal.Actions>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form error={error ? true : false} onSubmit={salvarEdicao}>
            <Form.Field>
              <label>Evento</label>
              <input
                placeholder="Digite o nome do evento"
                onChange={(e) => setTitleEvent(e.target.value)}
                value={titleEvent}
              />
            </Form.Field>
            <Form.Field>
              <label>Descrição</label>
              <TextArea
                placeholder="Digite a descrição do evento"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                maxLength="100"
              />
            </Form.Field>
            <Form.Field>
              <label>Data de início</label>
              <DatePicker
                className="myDatepicker"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="pt-BR"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Tempo"
                dateFormat="d/MM/yyyy HH:mm:00"
                placeholderText="Selecione a data e hora de ínicio"
              />
            </Form.Field>
            <Form.Field>
              <label>Data de término</label>
              <DatePicker
                className="myDatepicker"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                locale="pt-BR"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Tempo"
                dateFormat="d/MM/yyyy HH:mm:00"
                placeholderText="Selecione a data e hora de término"
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                fluid
                label="Sala"
                options={optionsSala}
                placeholder="Selecione a sala"
                value={sala}
                onChange={(e, { value }) => setSala(value)}
              />
            </Form.Field>
            <Message
              error
              header="Todos os campos são obrigatórios"
              content="Preencha todos os campos para poder inserir um novo evento."
            />
            <Button primary type="submit" disabled={isLoading}>
              Salvar {isLoading ? <Loader active inline size="mini" /> : null}
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
