import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import * as labels from "./labels/auscultacaoLabels";

export default function Auscultacao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [fala, setFala] = useState(acupuntura.auscultacao.fala);
  const [observacaoFala, setObservacaoFala] = useState(
    acupuntura.auscultacao.observacaoFala
  );
  const [respiracao, setRespiracao] = useState(
    acupuntura.auscultacao.respiracao
  );
  const [observacaoRespiracao, setObservacaoRespiracao] = useState(
    acupuntura.auscultacao.observacaoRespiracao
  );

  const checkboxesFala = () => {
    return labels.labelFala.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={fala.includes(la.value)}
                  onChange={(e, data) => {
                    if (fala.includes(data.value)) {
                      setFala(fala.filter((e) => e !== data.value));
                    } else {
                      setFala([...fala, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  const checkboxesRespiracao = () => {
    return labels.labelRespiracao.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={respiracao.includes(la.value)}
                  onChange={(e, data) => {
                    if (respiracao.includes(data.value)) {
                      setRespiracao(respiracao.filter((e) => e !== data.value));
                    } else {
                      setRespiracao([...respiracao, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  useEffect(() => {
    dispatch(
      actions.setAuscultacao({
        fala: fala,
        observacaoFala: observacaoFala,
        respiracao: respiracao,
        observacaoRespiracao: observacaoRespiracao,
      })
    );
  }, [dispatch, fala, observacaoFala, respiracao, observacaoRespiracao]);

  return (
    <React.Fragment>
      <Form.Field>
        <label>Fala</label>
        <Grid>
          <Grid.Row>{checkboxesFala()}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={observacaoFala}
          onChange={(e) => setObservacaoFala(e.target.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Respiração</label>
        <Grid>
          <Grid.Row>{checkboxesRespiracao()}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={observacaoRespiracao}
          onChange={(e) => setObservacaoRespiracao(e.target.value)}
        />
      </Form.Field>
    </React.Fragment>
  );
}
