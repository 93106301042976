import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./main.css";
import ModalEvent from "./ModalEvent";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrid from "@fullcalendar/timegrid";
import list from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "./main.scss"; // webpack must be configured to do this
import Sidebar from "../../assets/Sidebar";
import {
  Message,
  Grid,
  Form,
  Button,
  TextArea,
  Loader,
} from "semantic-ui-react";

import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pt from "date-fns/locale/pt-BR";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");
registerLocale("pt-BR", pt);

const optionsSala = [
  { key: "Sala 1", value: "Sala 1", text: "Sala 1" },
  { key: "Sala 2", value: "Sala 2", text: "Sala 2" },
];

export default function Scheduler() {
  const [modalEventOpen, setModalEventOpen] = useState(false);
  const [titleEvent, setTitleEvent] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState({});
  const [qtdRepeticao, setQtdRepeticao] = useState(0);
  const [sala, setSala] = useState("");
  const [events, setEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function submitEvent() {
    if (isFormNotEmpty()) {
      setIsLoading(true);
      const nomeCliente = titleEvent;
      const e = {
        nome: nomeCliente,
        descricao: description,
        dataComeco: startDate,
        dataFinal: endDate,
        sala: sala,
      };
      let startDateAux = startDate;
      let endDateAux = endDate;

      let events = [];

      events.push(e);

      for (var i = 0; i < qtdRepeticao; i++) {
        const sd = moment(startDateAux).add(7, "days");
        const ed = moment(endDateAux).add(7, "days");
        events.push({
          nome: nomeCliente,
          descricao: description,
          dataComeco: sd,
          dataFinal: ed,
          sala: sala,
        });
        startDateAux = sd;
        endDateAux = ed;
      }

      axios
        .post(process.env.REACT_APP_API_ENDPOINT + `Agenda`, { events })
        .then((res) => {
          setIsLoading(false);
          if (!res.error) {
            toast.success("Evento criado com sucesso");
            getEvents();
            cleanForm();
          } else {
            toast.error("Erro ao criar evento");
          }
        });
    }
  }

  function mobileCheck() {
    if (window.innerWidth >= 1057) {
      return false;
    } else {
      return true;
    }
  }

  function isFormNotEmpty() {
    if (
      titleEvent === "" ||
      startDate === "" ||
      endDate === "" ||
      description === "" ||
      qtdRepeticao === "" ||
      sala === ""
    ) {
      toast.warning("Todos os campos sao obrigatórios");
      return false;
    } else {
      return true;
    }
  }

  function cleanForm() {
    setError({});
    setTitleEvent("");
    setStartDate("");
    setEndDate("");
    setDescription("");
    setQtdRepeticao(0);
    setSala("");
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  function handleEventClick(e) {
    const eve = events.filter((ev) => ev.id == e.event.id);
    setEventSelected({
      id: e.event.id,
      title: e.event.title,
      start: e.event.start,
      end: e.event.end,
      description: eve[0].description,
      sala: eve[0].sala,
    });
    setModalEventOpen(true);
  }

  function deleteEvent(id) {
    setIsLoading(true);
    axios
      .delete(process.env.REACT_APP_API_ENDPOINT + `Agenda/${id}`)
      .then((res) => {
        setIsLoading(false);
        getEvents();
        setModalEventOpen(false);
        if (!res.error) {
          toast.success("Evento excluído com sucesso");
          getEvents();
          cleanForm();
        } else {
          toast.error("Erro ao excluir evento");
        }
      });
  }

  function editEvent(eventEdited) {
    setIsLoading(true);
    axios
      .put(process.env.REACT_APP_API_ENDPOINT + `Agenda`, eventEdited)
      .then((res) => {
        setIsLoading(false);
        getEvents();
        setModalEventOpen(false);
        if (!res.error) {
          toast.success("Evento editado com sucesso");
          getEvents();
          cleanForm();
        } else {
          toast.error("Erro ao editar evento");
        }
      });
  }

  function getEvents() {
    axios.get(process.env.REACT_APP_API_ENDPOINT + `Agenda`).then((res) => {
      let eventsAgenda = [];

      res.data.map((ev) => {
        const color = ev.sala === "Sala 1" ? "#099ade" : "#fc9003";
        const e = {
          id: ev.id,
          title: ev.nome,
          start: ev.dataComeco,
          end: ev.dataFinal,
          description: ev.descricao,
          sala: ev.sala,
          color: color,
        };
        eventsAgenda.push(e);
      });
      setEvents(eventsAgenda);
    });
  }

  useEffect(() => {
    getEvents();
  }, []);

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[1] === "1") {
      return true;
    } else {
      return false;
    }
  }

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          {modalEventOpen ? (
            <ModalEvent
              modalEventOpen={modalEventOpen}
              modalEventClose={() => setModalEventOpen(false)}
              eventSelected={eventSelected}
              deleteEvent={deleteEvent}
              editEvent={editEvent}
              isLoading={isLoading}
            />
          ) : null}
          <Message
            icon="calendar"
            header="Agenda"
            content="Verifique os próximos compromissos."
          />
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={4}>
                <div>
                  <Message
                    attached
                    header="Criar novo evento"
                    content="Preencha os campos abaixo para criar um novo agendamento no calendário."
                  />
                  <Form
                    error={error ? true : false}
                    onSubmit={submitEvent}
                    className="attached fluid segment"
                  >
                    <Form.Field>
                      <label>Evento</label>
                      <input
                        placeholder="Digite o nome do evento"
                        onChange={(e) => setTitleEvent(e.target.value)}
                        value={titleEvent}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Descrição</label>
                      <TextArea
                        placeholder="Digite a descrição do evento"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                        maxLength="100"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Data de início</label>
                      <DatePicker
                        className="myDatepicker"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        locale="pt-BR"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Tempo"
                        dateFormat="d/MM/yyyy HH:mm:00"
                        placeholderText="Selecione a data e hora de ínicio"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Data de término</label>
                      <DatePicker
                        className="myDatepicker"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        locale="pt-BR"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Tempo"
                        dateFormat="d/MM/yyyy HH:mm:00"
                        placeholderText="Selecione a data e hora de término"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Quantidade de repetições</label>
                      <input
                        placeholder="Digite a quantidade que o evento será repetido"
                        type="number"
                        value={qtdRepeticao}
                        onChange={(e) => setQtdRepeticao(e.target.value)}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Select
                        fluid
                        label="Sala"
                        options={optionsSala}
                        placeholder="Selecione a sala"
                        value={sala}
                        onChange={(e, { value }) => setSala(value)}
                      />
                    </Form.Field>
                    <Message
                      error
                      header={error.header}
                      content={error.content}
                    />
                    <Button
                      className="btnSelected"
                      type="submit"
                      disabled={isLoading}
                    >
                      Adicionar
                      {isLoading ? <Loader active inline size="mini" /> : null}
                    </Button>
                  </Form>
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <FullCalendar
                  defaultView={mobileCheck() ? "timeGridWeek" : "dayGridMonth"}
                  editable={true}
                  events={events}
                  plugins={[dayGridPlugin, timeGrid, list, interactionPlugin]}
                  locale="pt"
                  aspectRatio="2"
                  slotDuration="00:15:00"
                  eventClick={handleEventClick}
                  contentHeight="auto"
                  header={
                    !mobileCheck()
                      ? {
                          left: "prev,next today",
                          center: "title",
                          right:
                            "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                        }
                      : {
                          left:
                            "prev,next today, title, dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                          center: "",
                          right: "",
                        }
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <ToastContainer position="bottom-right" />
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
