import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import { isAuthenticated } from "./services/auth";
import Agenda from "../src/pages/Scheduler";
import Users from "../src/pages/Users/Users";
import Acupuntura from "../src/pages/Acupuntura";
import ConteudoEducacional from "../src/pages/ConteudoEducacional";
import Login from "../src/pages/Login";
import Avaliacao from "./pages/Avaliacao/main";
import Relatorios from "./pages/Relatorios";
import Usuarios from "./pages/Usuarios";
import Personal from "./pages/Personal";
import VisualizarAvaliacao from "./pages/VisualizarAvaliacao/main";
import VisualizarAcupuntura from "./pages/VisualizarAcupuntura";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated()) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        );
      }
    }}
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute path="/home" component={Home} />
      <PrivateRoute path="/Agenda" component={Agenda} />
      <PrivateRoute path="/Clientes" component={Users} />
      <PrivateRoute path="/Acupuntura" component={Acupuntura} />
      <PrivateRoute
        path="/VisualizarAcupuntura"
        component={VisualizarAcupuntura}
      />
      <PrivateRoute path="/AvaliacaoEnergetica" component={Avaliacao} />
      <PrivateRoute
        path="/VisualizarAvaliacaoEnergetica"
        component={VisualizarAvaliacao}
      />
      <PrivateRoute
        path="/ConteudoEducacional"
        component={ConteudoEducacional}
      />
      <PrivateRoute path="/Relatorios" component={Relatorios} />
      <PrivateRoute path="/Usuarios" component={Usuarios} />
      <PrivateRoute path="/Personal" component={Personal} />
      <Route path="*" component={Home} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
