import React, { useState, useEffect } from "react";
import {
  Form,
  Message,
  Table,
  Button,
  Popup,
  Icon,
  List,
  Loader,
  Pagination,
} from "semantic-ui-react";
import _ from "lodash";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ConsultarClientes(props) {
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  let tableClientes = "";

  useEffect(() => {
    getClientes();
  }, []);

  function getClientes() {
    setIsLoading(true);
    return axios
      .get(process.env.REACT_APP_API_ENDPOINT + `Usuario`)
      .then((res) => {
        setIsLoading(false);
        return res.data;
      });
  }

  function handleChangeInput(e) {
    setSearchValue(e.target.value);
  }

  async function handleSubmit() {
    setIsLoading(true);
    const c = await getClientes();
    setIsLoading(false);
    if (searchValue < 1) return setResults(c);

    const re = new RegExp(_.escapeRegExp(searchValue), "i");
    const isMatch = (result) => re.test(result.nome + " " + result.email);

    setResults(_.filter(c, isMatch));
  }

  async function setCli() {
    const c = await getClientes();
    setResults(c);
    setPages(Math.ceil(c.length / 10));
  }

  useEffect(() => {
    if (searchValue < 1) {
      setCli();
    }
  }, [searchValue]);

  function deletaCliente(id) {
    setIsLoading(true);
    axios
      .delete(process.env.REACT_APP_API_ENDPOINT + `Usuario/${id}`)
      .then((res) => {
        setIsLoading(false);
        if (!res.error) {
          toast.success("Usuário excluído com sucesso");
          handleSubmit();
        } else {
          toast.error("Erro ao excluir usuário");
        }
      });
  }

  if (results.length > 0) {
    const clientesRows = results.map((cliente, i) => {
      if (activePage * 10 - 10 <= i && i < activePage * 10)
        return (
          <Table.Row>
            {/* <Table.Cell>{<Image size="mini" src={cliente.image} />}</Table.Cell> */}
            <Table.Cell>{cliente.nome}</Table.Cell>
            <Table.Cell>{cliente.email}</Table.Cell>
            <Table.Cell>
              <Popup
                on="click"
                content={
                  <List selection>
                    <List.Item>
                      <Button
                        color="yellow"
                        size="mini"
                        fluid
                        compact
                        onClick={() => props.editaCliente(cliente.id)}
                        disabled={isLoading}
                      >
                        <Icon name="edit" />
                        Editar
                      </Button>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="red"
                        size="mini"
                        fluid
                        compact
                        onClick={() => deletaCliente(cliente.id)}
                        disabled={isLoading}
                      >
                        <Icon name="trash" />
                        Deletar
                        {isLoading ? (
                          <Loader active inline size="mini" />
                        ) : null}
                      </Button>
                    </List.Item>
                  </List>
                }
                trigger={<Button icon="settings" />}
              />
            </Table.Cell>
          </Table.Row>
        );
    });

    tableClientes = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell>Avatar</Table.HeaderCell> */}
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{clientesRows}</Table.Body>
      </Table>
    );
  }

  return (
    <React.Fragment>
      <Message
        icon="search"
        header="Consultar Usuários"
        //content="Get the best news in your e-mail every day."
      />
      <Form onSubmit={handleSubmit}>
        <Form.Field>
          <Form.Input
            onChange={_.debounce(handleChangeInput, 500, {
              leading: true,
            })}
            loading={isLoading}
          />
        </Form.Field>
      </Form>
      {tableClientes}
      {isLoading ? <Loader active inline size="mini" /> : null}
      {results.length > 0 ? (
        <Pagination
          defaultActivePage={1}
          totalPages={pages}
          onPageChange={(event, data) => setActivePage(data.activePage)}
        />
      ) : null}
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}
