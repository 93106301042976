import React from "react";
import { Form } from "semantic-ui-react";
import "../index.css";

export default function ComposicaoCorporal() {
  return (
    <div className="divForm">
      <Form>
        <Form.Field>
          <label>Peso Total</label>
          <input placeholder="Peso Total" />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>% Gordura</label>
            <input placeholder="% Gordura" />
          </Form.Field>
          <Form.Field>
            <label>Kg</label>
            <input placeholder="Kg" />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>% Massa Magra</label>
            <input placeholder="% Massa Magra" />
          </Form.Field>
          <Form.Field>
            <label>Kg</label>
            <input placeholder="Kg" />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}
