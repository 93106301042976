export const labels = {
  coluna: [
    [
      { key: 1, text: "Cervical", value: 1 },
      { key: 2, text: "Torácica", value: 2 },
    ],
    [
      { key: 3, text: "Lombar", value: 3 },
      {
        key: 4,
        text: "Cervical com irradiação para membros superiores",
        value: 4,
      },
    ],
  ],
  doresMusculares: [
    [
      { key: 1, text: "Tendinites (estagnação de QI do F)", value: 1 },
      {
        key: 2,
        text:
          "Espasmo agudo, facada, pontada, inflamação (vazio de Yin QI, QI perverso calor)",
        value: 2,
      },
      { key: 3, text: "Câimbras (def. Xue do F)", value: 3 },
    ],
    [
      {
        key: 4,
        text:
          "Contraturas, queimação, contínua e profunda (QI perverso frio, vazio de Yang QI)",
        value: 4,
      },
      { key: 5, text: "Dor migratória muscular (QI perverso vento)", value: 5 },
    ],
  ],
  doresArticulares: [
    [
      {
        key: 1,
        text:
          "Bi errante: dor nas articulações das extremidades com limitação de movimento (Qi perverso vento)",
        value: 1,
      },
      {
        key: 2,
        text:
          "Bi fixa: dor localizada com dormência e sensação de peso (estagnação de QI e Xue, Qi perverso frio/umidade)",
        value: 2,
      },
      {
        key: 3,
        text:
          "Bi dolorida: dor aliviada com calor agravada pelo frio (QI perverso frio)",
        value: 3,
      },
    ],
    [
      {
        key: 4,
        text: "Dores migratórias (Qi perverso vento, disfunção de VB)",
        value: 4,
      },
      {
        key: 5,
        text:
          "Bi febril: com vermelhidão, inchaço e sensibilidade local (Qi perverso vento/frio/umidade se transformam em calor)",
        value: 5,
      },
    ],
  ],
  abdomen: [
    [
      {
        key: 1,
        text: "Dores epigástricas (QI perverso frio no E)",
        value: 1,
      },
    ],
    [
      {
        key: 2,
        text: "Dor no hipocôndrio tipo pontada (estase de QI do F)",
        value: 2,
      },
    ],
  ],
  torax: [
    [
      {
        key: 1,
        text: "Dores (alterações patológicas do C e P)",
        value: 1,
      },
    ],
    [
      {
        key: 2,
        text: "Opressão (QI perverso frio e umdiade no P)",
        value: 2,
      },
    ],
  ],
  doresCabeca: [
    [
      {
        key: 1,
        text: "Frontal (retenção de alimentos no E)",
        value: 1,
      },
      {
        key: 2,
        text: "Orbital (def. Xue do F)",
        value: 2,
      },
    ],
    [
      {
        key: 3,
        text:
          "Temporal (hiperatividade de Yang QI do F, calor/umidade de F e VB)",
        value: 3,
      },
      {
        key: 4,
        text: "Occipital (distúrbio de B e ID)",
        value: 4,
      },
    ],
  ],
};
