export const labelFala = [
  [
    { key: 1, text: "Fala alta (síndrome de excesso, calor )", value: 1 },
    { key: 2, text: "Fala baixo (síndrome de def. , frio)", value: 2 },
    { key: 3, text: "Fala incoerente (debilidade do C)", value: 3 },
    { key: 4, text: "Fala muito (def. Yin do C )", value: 4 },
    {
      key: 5,
      text: "Gagueira (obstrução dos colaterais por Qi perverso vento/fleugma)",
      value: 5,
    },
  ],
  [
    {
      key: 6,
      text: "Pouca fala (síndr. def, Qi perverso frio ou lesão interna)",
      value: 6,
    },
    { key: 7, text: "Sem fala (frio interno, lesão interna)", value: 7 },
    {
      key: 8,
      text: "Afonia súbita (estase de Qi do P, obstrução de Qi do P)",
      value: 8,
    },
    {
      key: 9,
      text: "Rouquidão, afonia gradual (def. Yin Qi do P e R)",
      value: 9,
    },
  ],
];

export const labelRespiracao = [
  [
    { key: 1, text: "Respiração fraca (def. Qi do C e P)", value: 1 },
    { key: 2, text: "Falta de ar (def. Qi do P)", value: 2 },
    { key: 3, text: "Suspiro (estase do Qi do F)", value: 3 },
    {
      key: 4,
      text: "Tosse seca (Qi perverso secura , def. Yin do P)",
      value: 4,
    },
  ],
  [
    {
      key: 5,
      text: "Respiração forçada (flegma, Qi perverso umidade em P)",
      value: 5,
    },
    { key: 6, text: "Dispnéia (estase de Qi do P)", value: 6 },
    {
      key: 7,
      text: "Tosse rouca (Qi perverso vento-frio ou frio/flegma no P)",
      value: 7,
    },
    {
      key: 8,
      text: "Tosse clara(Qi pervers. vento/calor ou flegma/calor no P)",
      value: 8,
    },
  ],
];
