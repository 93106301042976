import React, { useState, useEffect } from "react";
import { Form, TextArea } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";

export default function Alimentacao() {
  const avEnergetica = useSelector((state) => state.avEnergetica);
  const [alimentacao, setAlimentacao] = useState(
    avEnergetica.alimentacao.alimentacao
  );
  const [sAlimentacao, setSAlimentacao] = useState(
    avEnergetica.alimentacao.sAlimentacao
  );
  const [comer, setComer] = useState(avEnergetica.alimentacao.comer);
  const [sComer, setSComer] = useState(avEnergetica.alimentacao.sComer);
  const [comeImportante, setComeImportante] = useState(
    avEnergetica.alimentacao.comeImportante
  );
  const [sComeImportante, setSComeImportante] = useState(
    avEnergetica.alimentacao.sComeImportante
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setAlimentacao({
        alimentacao: alimentacao,
        sAlimentacao: sAlimentacao,
        comer: comer,
        sComer: sComer,
        comeImportante: comeImportante,
        sComeImportante: sComeImportante,
      })
    );
  }, [
    dispatch,
    alimentacao,
    sAlimentacao,
    comer,
    sComer,
    comeImportante,
    sComeImportante,
  ]);

  return (
    <>
      <Form.Field>
        <label>Quantas alimentações faz por dia?</label>
        <TextArea
          value={alimentacao}
          onChange={(e) => setAlimentacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sugestão</label>
        <TextArea
          value={sAlimentacao}
          onChange={(e) => setSAlimentacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>O que você não gosta de comer?</label>
        <TextArea
          value={comer}
          onChange={(e) => setComer(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sugestão</label>
        <TextArea
          value={sComer}
          onChange={(e) => setSComer(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>O que você come que não é importante?</label>
        <TextArea
          value={comeImportante}
          onChange={(e) => setComeImportante(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sugestão</label>
        <TextArea
          value={sComeImportante}
          onChange={(e) => setSComeImportante(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </>
  );
}
