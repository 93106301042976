import React, { useState } from "react";
import { Button, Header, Icon, Modal, Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "./Forms/redux/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ModalExampleBasic({ sendForm }) {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button primary className="btnSelected" onClick={() => setOpen(true)}>
          Terminar sessão
        </Button>
      }
    >
      <Header icon>
        <Icon name="save" />
        Terminar sessão
      </Header>
      <Modal.Content>
        <p>Você tem certeza que deseja terminar esta sessão?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="red"
          inverted
          onClick={() => setOpen(false)}
          disabled={isLoading}
        >
          <Icon name="remove" /> Não
        </Button>
        <Button
          color="green"
          inverted
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const r = await sendForm();
            setIsLoading(false);
            if (!r.error) {
              dispatch(actions.clearStateAvaliacao());
              toast.success("Relatório criado com sucesso");
              setTimeout(() => {
                setOpen(false);
                history.push("/Home");
              }, 3000);
            } else {
              toast.error("Erro ao criar relatório");
            }
          }}
        >
          <Icon name="checkmark" /> Sim{" "}
          {isLoading ? <Loader active inline size="mini" /> : null}
        </Button>
      </Modal.Actions>
      <ToastContainer position="bottom-right" />
    </Modal>
  );
}

export default ModalExampleBasic;
