import React from "react";
import { Message } from "semantic-ui-react";
import "./styles.css";
import Sidebar from "../../assets/Sidebar";

import ImgHome from "../../assets/images/BackgroundHome.png";

function App() {
  return (
    <React.Fragment>
      <Sidebar />
      <div className="divContainer">
        <Message
          icon="bullhorn"
          header="Seja bem-vindo ao Wudang Dashboard !"
        />
        <div className="backgroundHome">
          <img src={ImgHome} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
