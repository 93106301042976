export const label = [
  [
    { key: 1, text: "Pálida e seca (def. Xue)", value: 1 },
    { key: 2, text: "Pálida e úmida (def. Yang Qi)", value: 2 },
    { key: 3, text: "Vermelha (calor, def. Yin Qi)", value: 3 },
    { key: 4, text: "Verm. c/ áreas averm. (estagnação de Xue)", value: 4 },
    { key: 5, text: "Ulcerada e vermelha (ascensão de fogo do C)", value: 5 },
    {
      key: 6,
      text: "Com fissuras (calor excessivo, def. Yin Qi do R)",
      value: 6,
    },
  ],
  [
    { key: 7, text: "Com saburra branca (frio interno)", value: 7 },
    { key: 8, text: "Com saburra amarela (calor interno)", value: 8 },
    { key: 9, text: "Sem saburra (Insuficiência de Qi doE)", value: 9 },
    { key: 10, text: "Denteada (umidade, def. Qi do BP)", value: 10 },
    { key: 11, text: "Inchada (umidade em BP)", value: 11 },
    { key: 12, text: "Púrpura ou violácea (estase de Xue)", value: 12 },
  ],
];
