const acupuntura = (
  state = {
    duracao: 0,
    isEdit: false,
    newTreatment: false,
    sessao: {
      queixaPrincipal: "",
      queixaSecundaria: "",
      observacao: "",
    },
    inspecao: {
      lingua: [],
      hma: "",
    },
    compleicao: {
      geral: [],
      aspectoGeral: "",
    },
    corPele: {
      corPele: [],
      expressaoRosto: "",
      cabecaCabelos: "",
      olhos: "",
      nariz: "",
      orelhas: "",
      labios: "",
      pele: "",
      observacao: "",
    },
    auscultacao: {
      fala: [],
      observacaoFala: "",
      respiracao: [],
      observacaoRespiracao: "",
    },
    interrogatorio: {
      transpiracao: [],
      obTranspiracao: "",
      observacaoFala: "",
      sono: [],
      obSono: "",
      emocoes: [],
      obEmocoes: "",
      alimentacao: [],
      obAlimentacao: "",
      sabores: [],
      obSabores: "",
      sede: [],
      obSede: "",
      disfuncoes: [],
      obDisfuncoes: "",
      excrecoes: [],
      obExcrecoes: "",
      menstruacao: [],
      obMenstruacao: "",
    },
    orgaoSentidos: {
      olhosVisao: [],
      ouvidosAudicao: [],
      narizOlfato: [],
      tato: [],
      bocaGosto: [],
      observacao: "",
    },
    algias: {
      coluna: [],
      obColuna: "",
      doresMusculares: [],
      doresArticulares: [],
      obdoresArticulares: "",
      abdomen: [],
      obAbdomen: "",
      torax: [],
      obTorax: "",
      doresCabeca: [],
      obDoresCabeca: "",
    },
    palpacao: {
      metalP: "",
      fogoC: "",
      terraBP: "",
      madF: "",
      fogoCS: "",
      aguaR: "",
      metalIG: "",
      fogoID: "",
      terraE: "",
      madVB: "",
      fogoTA: "",
      aguaB: "",
      pele: "",
      temperatura: "",
      abdome: "",
      sensibilidade: "",
    },
    cincoElementos: {
      aguaSize: "100px",
      fogoSize: "100px",
      madeiraSize: "100px",
      metalSize: "100px",
      terraSize: "100px",
      rotation1: 0,
      rotation2: 0,
      rotation3: 0,
      rotation4: 0,
      rotation5: 0,
      observacao: "",
    },
    acupuntura: {
      pontos: [],
    },
    lingua: {
      opacity1: false,
      opacity2: false,
      opacity3: false,
      opacity4: false,
      opacity5: false,
      opacity6: false,
      lingua1: "",
      lingua2: "",
      lingua3: "",
      lingua4: "",
      lingua5: "",
      lingua6: "",
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_SESSAO":
      return {
        ...state,
        sessao: action.payload,
      };
    case "SET_INSPECAO":
      return {
        ...state,
        inspecao: action.payload,
      };
    case "SET_COMPLEICAO":
      return {
        ...state,
        compleicao: action.payload,
      };
    case "SET_CORPELE":
      return {
        ...state,
        corPele: action.payload,
      };
    case "SET_AUSCULTACAO":
      return {
        ...state,
        auscultacao: action.payload,
      };
    case "SET_INTERROGATORIO":
      return {
        ...state,
        interrogatorio: action.payload,
      };
    case "SET_ORGAOSENTIDOS":
      return {
        ...state,
        orgaoSentidos: action.payload,
      };
    case "SET_ALGIAS":
      return {
        ...state,
        algias: action.payload,
      };
    case "SET_PALPACAO":
      return {
        ...state,
        palpacao: action.payload,
      };
    case "SET_CINCOELEMENTOS":
      return {
        ...state,
        cincoElementos: action.payload,
      };
    case "SET_ACUPUNTURA":
      return {
        ...state,
        acupuntura: action.payload,
      };
    case "SET_NOVO_TRATAMENTO":
      return {
        ...state,
        newTreatment: action.payload,
      };
    case "SET_LINGUA":
      return {
        ...state,
        lingua: action.payload,
      };
    case "CLEAR_STATE_ACUPUNTURA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_ACUPUNTURA_FORM":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default acupuntura;
