import React from "react";
import { Form, Table, Input, Divider, Button } from "semantic-ui-react";
import "../index.css";

export default function Exercicios() {
  const rows = () => {
    const rows = [];
    for (var i = 0; i < 1; i++) {
      rows.push(
        <Table.Row>
          <Table.Cell>Série {i + 1}</Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInputExercicio" />
          </Table.Cell>
          <Table.Cell>
            <Input size="mini" className="semanalInputExercicio" />
          </Table.Cell>
          <Table.Cell>
            <Button primary className="btnIniciar">+</Button>
          </Table.Cell>
        </Table.Row>
      );
    }
    return rows;
  };

  return (
    <div className="divForm">
      <Form>
        <Form.Field>
          <label>Grupo Musculares</label>
          <input placeholder="Grupo Musculares" />
        </Form.Field>
        <Form.Field>
          <label>Aquecimento</label>
          <input placeholder="Aquecimento" />
        </Form.Field>
        <Divider />
        <Form.Field>
          <label>Exercício 1</label>
          <input placeholder="Exercício 1" />
        </Form.Field>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Série</Table.HeaderCell>
              <Table.HeaderCell>Repetição</Table.HeaderCell>
              <Table.HeaderCell>Carga</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{rows()}</Table.Body>
        </Table>
        <Button primary className="btnIniciar">
          Adicionar Exercício
        </Button>
        <Divider />
        <Form.Group widths="equal">
          <Form.Field>
            <label>Atividade Aeróbica</label>
            <input placeholder="Atividade Aeróbica" />
          </Form.Field>
          <Form.Field>
            <label>Duração</label>
            <input placeholder="Duração" />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
  );
}
