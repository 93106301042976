export const label = [
  [
    { key: 1, text: "Face brilhante (normal)", value: 1 },
    { key: 2, text: "Face opaca (def. Qi)", value: 2 },
    {
      key: 3,
      text: "Face pálida (def. Qie Xue, def. Qi do C, frio)",
      value: 3,
    },
    { key: 4, text: "Face amarelada (E e BP)", value: 4 },
    {
      key: 5,
      text: "Face avermelhada (ascensão do fogo do F, calor)",
      value: 5,
    },
  ],
  [
    {
      key: 6,
      text: "Face esverdeada ou azulada (estagnação de Qi do F, dor grave)",
      value: 6,
    },
    { key: 7, text: "Face escurecida (def. de R)", value: 7 },
    { key: 8, text: "Pele seca (def. Qie meridianos)", value: 8 },
    { key: 9, text: "Pele úmida (def. Yin Qii)", value: 9 },
  ],
];
