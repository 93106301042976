import React, { useState } from "react";
import { Form, Button, Message, Loader } from "semantic-ui-react";
import { telefoneMask, cepMask, dataMask } from "./masks";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CadastrarClientes({ cliente }) {
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [primeiroNome, setPrimeiroNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [sexo, setSexo] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [atividadeProfissional, setAtividadeProfissional] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numCasa, setNumCasa] = useState("");
  const [bairro, setBairro] = useState("");
  const [cep, setCep] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const optionsEstados = [
    { key: "ac", value: "ac", text: "Acre" },
    { key: "al", value: "al", text: "Alagoas" },
    { key: "ap", value: "ap", text: "Amapá" },
    { key: "am", value: "am", text: "Amazonas" },
    { key: "ba", value: "ba", text: "Bahia" },
    { key: "ce", value: "ce", text: "Ceará" },
    { key: "df", value: "df", text: "Distrito Federal" },
    { key: "es", value: "es", text: "Espírito Santo" },
    { key: "go", value: "go", text: "Goiás" },
    { key: "ma", value: "ma", text: "Maranhão" },
    { key: "mt", value: "mt", text: "Mato Grosso" },
    { key: "ms", value: "ms", text: "Mato Grosso do Sul" },
    { key: "mg", value: "mg", text: "Minas Gerais" },
    { key: "pa", value: "pa", text: "Pará" },
    { key: "pb", value: "pb", text: "Paraíba" },
    { key: "pr", value: "pr", text: "Paraná" },
    { key: "pe", value: "pe", text: "Pernambuco" },
    { key: "pi", value: "pi", text: "Piauí" },
    { key: "rj", value: "rj", text: "Rio de Janeiro" },
    { key: "rn", value: "rn", text: "Rio Grande do Norte" },
    { key: "rs", value: "rs", text: "Rio Grande do Sul" },
    { key: "ro", value: "ro", text: "Rondônia" },
    { key: "rr", value: "rr", text: "Roraima" },
    { key: "sc", value: "sc", text: "Santa Catarina" },
    { key: "sp", value: "sp", text: "São Paulo" },
    { key: "se", value: "se", text: "Sergipe" },
    { key: "to", value: "to", text: "Tocantins" },
  ];
  const optionsCivil = [
    { key: "so", value: "so", text: "Solteiro (a)" },
    { key: "ca", value: "ca", text: "Casado (a)" },
    { key: "di", value: "di", text: "Divorciado (a)" },
    { key: "vi", value: "vi", text: "Viúvo (a)" },
    { key: "se", value: "se", text: "Separado (a)" },
  ];

  function sendForm() {
    if (isFormNotEmpty()) {
      const id = cliente.id;
      setIsLoading(true);
      const c = {
        id,
        primeiroNome,
        sobrenome,
        email,
        sexo,
        dataNasc,
        estadoCivil,
        telefone,
        celular,
        atividadeProfissional,
        nomePai,
        nomeMae,
        endereco,
        numCasa,
        bairro,
        cep,
        cidade,
        estado,
      };
      if (isEdit) {
        axios
          .put(process.env.REACT_APP_API_ENDPOINT + `Clientes`, c)
          .then((res) => {
            setIsLoading(false);
            if (!res.error) {
              toast.success("Cliente editado com sucesso");
              cleanForm();
            } else {
              toast.error("Erro ao editar cliente");
            }
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_ENDPOINT + `Clientes`, c)
          .then((res) => {
            setIsLoading(false);
            if (!res.error) {
              toast.success("Cliente criado com sucesso");
              cleanForm();
            } else {
              toast.error("Erro ao criar cliente");
            }
          });
      }
    }
  }

  function isFormNotEmpty() {
    if (
      primeiroNome === "" ||
      sobrenome === "" ||
      email === "" ||
      sexo === "" ||
      dataNasc === "" ||
      estadoCivil === "" ||
      telefone === "" ||
      celular === "" ||
      atividadeProfissional === "" ||
      nomePai === "" ||
      nomeMae === "" ||
      endereco === "" ||
      numCasa === "" ||
      bairro === "" ||
      cep === "" ||
      cidade === "" ||
      estado === ""
    ) {
      toast.warning("Todos os campos sao obrigatórios");
      return false;
    } else {
      return true;
    }
  }

  function cleanForm() {
    setIsEdit(false);
    setPrimeiroNome("");
    setSobrenome("");
    setEmail("");
    setSexo("");
    setDataNasc("");
    setEstadoCivil("");
    setTelefone("");
    setCelular("");
    setAtividadeProfissional("");
    setNomePai("");
    setNomeMae("");
    setEndereco("");
    setNumCasa("");
    setBairro("");
    setCep("");
    setCidade("");
    setEstado("");
  }

  function handleChangeCivil(e, { value }) {
    const found = optionsCivil.find((e) => e.value === value);
    setEstadoCivil(found.value);
  }

  function handleChangeEstado(e, { value }) {
    const found = optionsEstados.find((e) => e.value === value);
    setEstado(found.value);
  }

  useEffect(() => {
    if (cliente.id) {
      setIsEdit(true);
      setPrimeiroNome(cliente.primeiroNome);
      setSobrenome(cliente.sobrenome);
      setEmail(cliente.email);
      setSexo(cliente.sexo);
      setDataNasc(cliente.dataNasc);
      setEstadoCivil(cliente.estadoCivil);
      setTelefone(cliente.telefone);
      setCelular(cliente.celular);
      setAtividadeProfissional(cliente.atividadeProfissional);
      setNomePai(cliente.nomePai);
      setNomeMae(cliente.nomeMae);
      setEndereco(cliente.endereco);
      setNumCasa(cliente.numCasa);
      setBairro(cliente.bairro);
      setCep(cliente.cep);
      setCidade(cliente.cidade);
      setEstado(cliente.estado);
    }
  }, [cliente]);

  return (
    <React.Fragment>
      <Message
        icon="add user"
        header="Cadastrar Clientes"
        //content="Get the best news in your e-mail every day."
      />
      <Form>
        <Form.Field>
          <label>Primeiro Nome</label>
          <input
            placeholder="Primeiro Nome"
            value={primeiroNome}
            onChange={(e) => setPrimeiroNome(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Sobrenome</label>
          <input
            placeholder="Sobrenome"
            value={sobrenome}
            onChange={(e) => setSobrenome(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>E-mail</label>
          <input
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Field>
        <Form.Group inline>
          <label>Sexo</label>
          <Form.Radio
            label="Masculino"
            value="m"
            name="Sexo"
            checked={sexo === "m"}
            onChange={() => setSexo("m")}
          />
          <Form.Radio
            label="Feminino"
            value="f"
            name="Sexo"
            checked={sexo === "f"}
            onChange={() => setSexo("f")}
          />
        </Form.Group>
        <Form.Field>
          <label>Data de Aniversário</label>
          <input
            maxLength="10"
            placeholder="Digite a data de aniversário"
            value={dataNasc}
            onChange={(e) => setDataNasc(dataMask(e.target.value))}
          />
        </Form.Field>
        <Form.Field>
          <Form.Select
            fluid
            label="Estado Civil"
            options={optionsCivil}
            placeholder="Estado Civil"
            value={estadoCivil}
            onChange={handleChangeCivil}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Telefone</label>
            <input
              maxLength="15"
              placeholder="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(telefoneMask(e.target.value))}
            />
          </Form.Field>
          <Form.Field>
            <label>Celular</label>
            <input
              maxLength="15"
              placeholder="Celular"
              value={celular}
              onChange={(e) => setCelular(telefoneMask(e.target.value))}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Atividade Profissional</label>
          <input
            placeholder="Atividade Profissional"
            value={atividadeProfissional}
            onChange={(e) => setAtividadeProfissional(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Nome do Pai</label>
          <input
            placeholder="Nome do Pai"
            value={nomePai}
            onChange={(e) => setNomePai(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Nome da Mãe</label>
          <input
            placeholder="Nome da Mãe"
            value={nomeMae}
            onChange={(e) => setNomeMae(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Endereço</label>
          <input
            placeholder="Endereço"
            value={endereco}
            onChange={(e) => setEndereco(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Número</label>
          <input
            placeholder="Número"
            value={numCasa}
            onChange={(e) => setNumCasa(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Bairro</label>
          <input
            placeholder="Bairro"
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>CEP</label>
          <input
            maxLength="9"
            placeholder="CEP"
            value={cep}
            onChange={(e) => setCep(cepMask(e.target.value))}
          />
        </Form.Field>
        <Form.Field>
          <label>Cidade</label>
          <input
            placeholder="Cidade"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <Form.Select
            fluid
            label="Estado"
            options={optionsEstados}
            placeholder="Estado"
            value={estado}
            onChange={handleChangeEstado}
          />
        </Form.Field>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            type="submit"
            className="btnSelected"
            onClick={sendForm}
            disabled={isLoading}
          >
            Salvar <Loader active={isLoading} inline size="mini" />
          </Button>
        </div>
      </Form>
      <ToastContainer position="bottom-right" />
    </React.Fragment>
  );
}
