import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import { labels } from "./labels/orgaoDosSentidosLabels";

export default function OrgaoDosSentidos() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [olhosVisao, setOlhosVisao] = useState(
    acupuntura.orgaoSentidos.olhosVisao
  );
  const [ouvidosAudicao, setOuvidosAudicao] = useState(
    acupuntura.orgaoSentidos.ouvidosAudicao
  );
  const [narizOlfato, setNarizOlfato] = useState(
    acupuntura.orgaoSentidos.narizOlfato
  );
  const [tato, setTato] = useState(acupuntura.orgaoSentidos.tato);
  const [bocaGosto, setBocaGosto] = useState(
    acupuntura.orgaoSentidos.bocaGosto
  );
  const [observacao, setObservacao] = useState(
    acupuntura.orgaoSentidos.observacao
  );

  useEffect(() => {
    dispatch(
      actions.setOrgaoSentidos({
        olhosVisao: olhosVisao,
        ouvidosAudicao: ouvidosAudicao,
        narizOlfato: narizOlfato,
        tato: tato,
        bocaGosto: bocaGosto,
        observacao: observacao,
      })
    );
  }, [
    dispatch,
    olhosVisao,
    ouvidosAudicao,
    narizOlfato,
    tato,
    bocaGosto,
    observacao,
  ]);

  const checkboxes = (nomeEstado, estado, setEstado) => {
    let labelArray = labels[nomeEstado];
    return labelArray.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={estado.includes(la.value)}
                  onChange={(e, data) => {
                    if (estado.includes(data.value)) {
                      setEstado(estado.filter((e) => e !== data.value));
                    } else {
                      setEstado([...estado, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  return (
    <React.Fragment>
      <Form.Field>
        <label>Olhos e visão</label>
        <Grid>
          <Grid.Row>
            {checkboxes("olhosVisao", olhosVisao, setOlhosVisao)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Ouvidos e audição</label>
        <Grid>
          <Grid.Row>
            {checkboxes("ouvidosAudicao", ouvidosAudicao, setOuvidosAudicao)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Nariz e Olfato</label>
        <Grid>
          <Grid.Row>
            {checkboxes("narizOlfato", narizOlfato, setNarizOlfato)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Tato</label>
        <Grid>
          <Grid.Row>{checkboxes("tato", tato, setTato)}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Boca e gosto</label>
        <Grid>
          <Grid.Row>
            {checkboxes("bocaGosto", bocaGosto, setBocaGosto)}
          </Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
