import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import ConsultarClientes from "./ConsultarClientes";
import CadastrarClientes from "./CadastrarClientes";
import axios from "axios";
import Sidebar from "../../assets/Sidebar";

export default function App() {
  const [editCliente, setEditCliente] = useState({});

  function editaCliente(id) {
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + `Usuario/${id}`)
      .then((res) => {
        setEditCliente(res.data);
      });
  }

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[13] === "1") {
      return true;
    } else {
      return false;
    }
  }

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <ConsultarClientes editaCliente={editaCliente} />
              </Grid.Column>
              <Grid.Column width={8}>
                <CadastrarClientes usuario={editCliente} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
