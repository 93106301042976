import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import * as labels from "./labels/inspecaoLabels";

export default function Inspecao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [lingua, setLingua] = useState(acupuntura.inspecao.lingua);
  const [hma, setHma] = useState(acupuntura.inspecao.hma);

  useEffect(() => {
    dispatch(
      actions.setInspecao({
        lingua: lingua,
        hma: hma,
      })
    );
  }, [dispatch, lingua, hma]);

  const checkboxes = () => {
    return labels.label.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={lingua.includes(la.value)}
                  onChange={(e, data) => {
                    if (lingua.includes(data.value)) {
                      setLingua(lingua.filter((e) => e !== data.value));
                    } else {
                      setLingua([...lingua, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  return (
    <React.Fragment>
      <Form.Field>
        <label>Língua</label>
        <Grid>
          <Grid.Row>{checkboxes()}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>História da Moléstia Atual (HMA)</label>
        <TextArea
          value={hma}
          onChange={(e) => setHma(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
