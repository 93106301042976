import React, { useEffect, useState } from "react";
import { Form, TextArea } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";

export default function Fisico() {
  const avEnergetica = useSelector((state) => state.avEnergetica);
  const [peso, setPeso] = useState(avEnergetica.fisico.peso);
  const [altura, setAltura] = useState(avEnergetica.fisico.altura);
  const [pressaoArterial, setPressaoArterial] = useState(
    avEnergetica.fisico.pressaoArterial
  );
  const [transpiracao, setTranspiracao] = useState(
    avEnergetica.fisico.transpiracao
  );
  const [dor, setDor] = useState(avEnergetica.fisico.dor);
  const [fezes, setFezes] = useState(avEnergetica.fisico.fezes);
  const [urina, setUrina] = useState(avEnergetica.fisico.urina);
  const [audicao, setAudicao] = useState(avEnergetica.fisico.audicao);
  const [olfato, setOlfato] = useState(avEnergetica.fisico.olfato);
  const [visao, setVisao] = useState(avEnergetica.fisico.visao);
  const [vidaSexual, setVidaSexual] = useState(avEnergetica.fisico.vidaSexual);
  const [menstruacao, setMenstruacao] = useState(
    avEnergetica.fisico.menstruacao
  );
  const [ejaculacao, setEjaculacao] = useState(avEnergetica.fisico.ejaculacao);
  const [vidaEmocional, setVidaEmocional] = useState(
    avEnergetica.fisico.vidaEmocional
  );
  const [gostoLingua, setGostoLingua] = useState(
    avEnergetica.fisico.gostoLingua
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setFisico({
        peso: peso,
        altura: altura,
        pressaoArterial: pressaoArterial,
        transpiracao: transpiracao,
        dor: dor,
        fezes: fezes,
        urina: urina,
        audicao: audicao,
        olfato: olfato,
        visao: visao,
        vidaSexual: vidaSexual,
        menstruacao: menstruacao,
        ejaculacao: ejaculacao,
        vidaEmocional: vidaEmocional,
        gostoLingua: gostoLingua,
      })
    );
  }, [
    dispatch,
    peso,
    altura,
    pressaoArterial,
    transpiracao,
    dor,
    fezes,
    urina,
    audicao,
    olfato,
    visao,
    vidaSexual,
    menstruacao,
    ejaculacao,
    vidaEmocional,
    gostoLingua,
  ]);

  return (
    <>
      <Form.Field>
        <label>Peso</label>
        <TextArea
          value={peso}
          onChange={(e) => setPeso(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Altura</label>
        <TextArea
          value={altura}
          onChange={(e) => setAltura(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Pressão Arterial</label>
        <TextArea
          value={pressaoArterial}
          onChange={(e) => setPressaoArterial(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Transpiração em que parte do corpo e que horas do dia?</label>
        <TextArea
          value={transpiracao}
          onChange={(e) => setTranspiracao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>
          Dor(local, sensação, fatores que piora/melhora e horário)?
        </label>
        <TextArea
          value={dor}
          onChange={(e) => setDor(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Fezes quantas vezes ao dia e como é?</label>
        <TextArea
          value={fezes}
          onChange={(e) => setFezes(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Urina quantas vezes ao dia e como é?</label>
        <TextArea
          value={urina}
          onChange={(e) => setUrina(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Audição</label>
        <TextArea
          value={audicao}
          onChange={(e) => setAudicao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Olfato</label>
        <TextArea
          value={olfato}
          onChange={(e) => setOlfato(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Visão</label>
        <TextArea
          value={visao}
          onChange={(e) => setVisao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Vida Sexual</label>
        <TextArea
          value={vidaSexual}
          onChange={(e) => setVidaSexual(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Menstruação</label>
        <TextArea
          value={menstruacao}
          onChange={(e) => setMenstruacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Ejaculação</label>
        <TextArea
          value={ejaculacao}
          onChange={(e) => setEjaculacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Vida Emocional</label>
        <TextArea
          value={vidaEmocional}
          onChange={(e) => setVidaEmocional(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sente algum tipo de gosto na língua?</label>
        <TextArea
          value={gostoLingua}
          onChange={(e) => setGostoLingua(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </>
  );
}
