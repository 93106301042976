import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, Input, Button, Table, Icon } from "semantic-ui-react";
import "../main.css";

export default function AcupunturaSessao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [pontos, setPontos] = useState(acupuntura.acupuntura.pontos);
  const [pontoInput, setPontoInput] = useState("");
  const [acaoInput, setAcaoInput] = useState("");

  useEffect(() => {
    dispatch(
      actions.setAcupuntura({
        pontos: pontos,
      })
    );
  }, [dispatch, pontos]);

  const tableRows = pontos.map((p, index) => {
    return (
      <Table.Row>
        <Table.Cell>{p.ponto}</Table.Cell>
        <Table.Cell>{p.acao}</Table.Cell>
        <Table.Cell>
          <Button
            color="red"
            animated="vertical"
            onClick={() => deletarPonto(index)}
          >
            <Button.Content hidden>Deletar</Button.Content>
            <Button.Content visible>
              <Icon name="trash" />
            </Button.Content>
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  });

  function deletarPonto(index) {
    let pontosArray = [...pontos];
    pontosArray.splice(index, 1);
    setPontos(pontosArray);
  }

  function gravarPontos() {
    let pontosArray = [...pontos];
    pontosArray.push({ ponto: pontoInput, acao: acaoInput });
    setPontos(pontosArray);
    setPontoInput("");
    setAcaoInput("");
  }

  return (
    <React.Fragment>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Ponto</label>
          <Input
            value={pontoInput}
            onChange={(e, { value }) => setPontoInput(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Ação</label>
          <Input
            value={acaoInput}
            onChange={(e, { value }) => setAcaoInput(value)}
          />
        </Form.Field>
        <Form.Field className="gravarButtonField">
          <Button onClick={gravarPontos}>Gravar</Button>
        </Form.Field>
      </Form.Group>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ponto</Table.HeaderCell>
            <Table.HeaderCell>Ação</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </React.Fragment>
  );
}
