const cliente = (
  state = {
    cliente: {
      id: 0,
      nome: "",
      email: "",
      image: "",
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_CLIENTE":
      return {
        ...state,
        cliente: action.payload,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default cliente;
