import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Checkbox, Grid } from "semantic-ui-react";
import * as labels from "./labels/corDaPeleLabels";

export default function CorDaPele() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [corPele, setCorPele] = useState(acupuntura.corPele.corPele);
  const [expressaoRosto, setExpressaoRosto] = useState(
    acupuntura.corPele.expressaoRosto
  );
  const [cabecaCabelos, setCabecaCabelos] = useState(
    acupuntura.corPele.cabecaCabelos
  );
  const [olhos, setOlhos] = useState(acupuntura.corPele.olhos);
  const [nariz, setNariz] = useState(acupuntura.corPele.nariz);
  const [orelhas, setOrelhas] = useState(acupuntura.corPele.orelhas);
  const [labios, setLabios] = useState(acupuntura.corPele.labios);
  const [pele, setPele] = useState(acupuntura.corPele.pele);
  const [observacao, setObservacao] = useState(acupuntura.corPele.observacao);

  const checkboxes = () => {
    return labels.label.map((l) => {
      return (
        <Grid.Column width={8}>
          {l.map((la) => {
            return (
              <Form.Field>
                <Checkbox
                  label={la.text}
                  value={la.value}
                  checked={corPele.includes(la.value)}
                  onChange={(e, data) => {
                    if (corPele.includes(data.value)) {
                      setCorPele(corPele.filter((e) => e !== data.value));
                    } else {
                      setCorPele([...corPele, data.value]);
                    }
                  }}
                />
              </Form.Field>
            );
          })}
        </Grid.Column>
      );
    });
  };

  useEffect(() => {
    dispatch(
      actions.setCorPele({
        corPele: corPele,
        expressaoRosto: expressaoRosto,
        cabecaCabelos: cabecaCabelos,
        olhos: olhos,
        nariz: nariz,
        orelhas: orelhas,
        labios: labios,
        pele: pele,
        observacao: observacao,
      })
    );
  }, [
    dispatch,
    corPele,
    expressaoRosto,
    cabecaCabelos,
    olhos,
    nariz,
    orelhas,
    labios,
    pele,
    observacao,
  ]);

  return (
    <React.Fragment>
      <Form.Field>
        <Grid>
          <Grid.Row>{checkboxes()}</Grid.Row>
        </Grid>
      </Form.Field>
      <Form.Field>
        <label>Expressão do Rosto</label>
        <TextArea
          value={expressaoRosto}
          onChange={(e) => setExpressaoRosto(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Cabeça e cabelos</label>
        <TextArea
          value={cabecaCabelos}
          onChange={(e) => setCabecaCabelos(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>
          Olhos (brilho, aspecto, coloração, movimentos, edema de pálpebras)
        </label>
        <TextArea
          value={olhos}
          onChange={(e) => setOlhos(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Nariz (Deformidades, secreções)</label>
        <TextArea
          value={nariz}
          onChange={(e) => setNariz(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Orelhas (Coloração, brilho,aspecto interior)</label>
        <TextArea
          value={orelhas}
          onChange={(e) => setOrelhas(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>
          Lábios, dentes, gengiva, garganta (cor, umidade, forma, ulceração,
          morfologia)
        </label>
        <TextArea
          value={labios}
          onChange={(e) => setLabios(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>
          Pele (alterações da cor, textura, cicatrizes, alterações morfológicas)
        </label>
        <TextArea
          value={pele}
          onChange={(e) => setPele(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Observação</label>
        <TextArea
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
