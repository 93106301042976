import React, { useState } from "react";
import "./main.css";
import ModalWindow from "../Modal/index";
import { Link, useHistory } from "react-router-dom";
import { Menu, Icon, Image, Dropdown, Divider } from "semantic-ui-react";
import { logout } from "../../services/auth";

export default function SidebarMenu(props) {
  const [activeItem, setActiveItem] = useState("Home");
  const [sidebarClass, setSidebarClass] = useState("leftSidebar");
  const [nameUser, setNameUser] = useState(
    localStorage.getItem("@wd_username")
  );
  let history = useHistory();

  const trigger = (
    <span>
      <Icon name="setting" />
    </span>
  );

  function signout() {
    logout();
    localStorage.removeItem("@wd_username");
    localStorage.removeItem("@wd_email");
    localStorage.removeItem("@wd_acessos");
    history.push("/");
  }

  function openSidebar() {
    if (sidebarClass === "leftSidebar") setSidebarClass("leftSidebarOpen");
    else setSidebarClass("leftSidebar");
  }

  function checkAccess(nome) {
    const acessos = localStorage.getItem("@wd_acessos");
    switch (nome) {
      case "users":
        if (acessos[3] === "1") {
          return true;
        } else {
          return false;
        }
      case "scheduler":
        if (acessos[1] === "1") {
          return true;
        } else {
          return false;
        }
      case "relatorios":
        if (acessos[5] === "1") {
          return true;
        } else {
          return false;
        }
      case "conteudoeducacional":
        if (acessos[7] === "1") {
          return true;
        } else {
          return false;
        }
      case "avaliacao":
        if (acessos[9] === "1") {
          return true;
        } else {
          return false;
        }
      case "acupuntura":
        if (acessos[11] === "1") {
          return true;
        } else {
          return false;
        }
      case "usuarios":
        if (acessos[13] === "1") {
          return true;
        } else {
          return false;
        }
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <Menu
        vertical
        fixed="left"
        className={sidebarClass}
        style={{ zIndex: "999" }}
      >
        <div className="divHeaderMenu">
          <Link to="/Home">
            <Menu.Item name="logo" className="logoNavbar">
              <Image
                src="WudangLogo2.jpeg"
                size="small"
                className="logoNavbarImage"
              />
            </Menu.Item>
          </Link>
          <button onClick={openSidebar} className="buttonCloseSidebar">
            <Icon name="close" />
          </button>
        </div>
        <ModalWindow history={history} />
        <Link to="/Home">
          <Menu.Item
            name="Home"
            active={activeItem === "Home"}
            onClick={() => setActiveItem("Home")}
          >
            <Icon name="home" />
            Home
          </Menu.Item>
        </Link>
        {checkAccess("users") ? (
          <Link to="/Clientes">
            <Menu.Item
              name="Clientes"
              active={activeItem === "Clientes"}
              onClick={() => setActiveItem("Clientes")}
            >
              <Icon name="user" />
              Clientes
            </Menu.Item>
          </Link>
        ) : null}
        {/* <Link to="/Personal">
          <Menu.Item
            name="Personal"
            active={activeItem === "Personal"}
            onClick={() => setActiveItem("Personal")}
          >
            <Icon name="heartbeat" />
            Personal
          </Menu.Item>
        </Link> */}
        {checkAccess("conteudoeducacional") ? (
          <Link to="/ConteudoEducacional">
            <Menu.Item
              name="ConteudoEducacional"
              active={activeItem === "ConteudoEducacional"}
              onClick={() => setActiveItem("ConteudoEducacional")}
            >
              <Icon name="book" />
              Conteúdo Educacional
            </Menu.Item>
          </Link>
        ) : null}
        <Divider className="dividerSidebar" />
        {checkAccess("scheduler") ? (
          <Link to="/Agenda" className="agendaSidebar">
            <Menu.Item
              name="Agenda"
              active={activeItem === "Agenda"}
              onClick={() => setActiveItem("Agenda")}
            >
              <Icon name="calendar" />
              Agenda
            </Menu.Item>
          </Link>
        ) : null}
        {checkAccess("relatorios") ? (
          <Link to="/Relatorios" className="relatorioSidebar">
            <Menu.Item
              name="Relatórios"
              active={activeItem === "Relatórios"}
              onClick={() => setActiveItem("Relatórios")}
            >
              <Icon name="file alternate" />
              Relatórios
            </Menu.Item>
          </Link>
        ) : null}
      </Menu>
      <Menu
        inverted
        className="navbar"
        fixed="top"
        fluid
        style={{ height: "60px", paddingLeft: "211px" }}
      >
        <Menu.Item name="menu" id="menuButton" onClick={openSidebar}>
          <Icon name="bars" />
        </Menu.Item>
        {checkAccess("scheduler") ? (
          <Link to="/Agenda" className="topMenuItem">
            <Menu.Item name="scheduler">
              <Icon name="calendar" />
              Agenda
            </Menu.Item>
          </Link>
        ) : null}
        {checkAccess("relatorios") ? (
          <Link to="/Relatorios" className="topMenuItem">
            <Menu.Item name="reviews">
              <Icon name="file alternate" />
              Relatórios
            </Menu.Item>
          </Link>
        ) : null}
        <Menu.Menu position="right">
          <Menu.Item name="user" id="userItem">
            <Image
              src="https://react.semantic-ui.com/images/avatar/large/matthew.png"
              avatar
            />
            {nameUser}
          </Menu.Item>
          <Menu.Item name="opcoes">
            <Dropdown item trigger={trigger}>
              <Dropdown.Menu>
                {checkAccess("usuarios") ? (
                  <Link to="/Usuarios">
                    <Dropdown.Item style={{ color: "#000" }}>
                      Usuários
                    </Dropdown.Item>
                  </Link>
                ) : null}
                <Dropdown.Item style={{ color: "#000" }} onClick={signout}>
                  Sair
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </React.Fragment>
  );
}
