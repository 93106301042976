import React from "react";
import {
  Table,
  Button,
  Popup,
  Icon,
  List,
  Search,
  Message,
  Loader,
  Pagination,
} from "semantic-ui-react";
import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import * as moment from "moment";
import Sidebar from "../../assets/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import actions from "../Avaliacao/Forms/redux/actions";
import actionsModal from "../../assets/Modal/redux/actions";
import actionsAcupuntura from "../Acupuntura/Forms/redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import { AcupunturaPDF } from "../../PDFs/AcupunturaPDF";
import { AvEnergeticaPDF } from "../../PDFs/AvEnergeticaPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "moment/locale/pt-br";
moment.locale("pt-br");

const initialState = { isLoading: false, results: [], selected: {}, value: "" };

export default function Relatorios() {
  const [state, setState] = useState(initialState);
  const [source, setSource] = useState([]);
  const [relatorios, setRelatorios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  let tableRelatorios = "";
  let history = useHistory();
  const location = useLocation();

  const handleResultSelect = (e, { result }) =>
    setState({
      isLoading: false,
      results: [],
      selected: {
        id: result.id,
        title: result.title,
        description: result.description,
        image: result.image,
      },
      value: result.title,
    });

  const handleSearchChange = (e, { value }) => {
    setState({ isLoading: true, results: [], selected: {}, value: value });
  };

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[5] === "1") {
      return true;
    } else {
      return false;
    }
  }

  function getClientes() {
    return axios
      .get(process.env.REACT_APP_API_ENDPOINT + `Clientes`)
      .then((res) => {
        let clientes = [];

        res.data.map((cliente) => {
          const c = {
            id: cliente.id,
            title: cliente.primeiroNome + " " + cliente.sobrenome,
            description: cliente.email,
            image:
              "https://s3.amazonaws.com/uifaces/faces/twitter/stefanotirloni/128.jpg",
          };
          clientes.push(c);
        });
        setSource(clientes);
      });
  }

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    if (state.value.length < 1) return setState(initialState);

    const re = new RegExp(_.escapeRegExp(state.value), "i");
    const isMatch = (result) => re.test(result.title);

    setState({
      isLoading: false,
      results: _.filter(source, isMatch),
      selected: state.selected,
      value: state.value,
    });
  }, [state.value, source]);

  function setRelat(bool) {
    setIsLoading(true);
    if (bool) {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `PegarTodosRelatorios`)
        .then((res) => {
          setIsLoading(false);
          let newRes = [];
          res.data.map((r, index) => {
            r.map((item) => {
              if (index === 0)
                newRes.push({
                  ...item,
                  relatorio: "Avaliação Energética",
                });
              else newRes.push({ ...item, relatorio: "Acupuntura" });
            });
          });
          const rel = newRes.sort(compare);
          setRelatorios(rel);
          setPages(Math.ceil(rel.length / 10));
        });
    } else {
      const id = state.selected.id
        ? state.selected.id
        : location.state.clienteID;
      location.state = undefined;
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `PegarListaRelatorios/` + id)
        .then((res) => {
          setIsLoading(false);
          let newRes = [];
          res.data.map((r, index) => {
            r.map((item) => {
              if (index === 0)
                newRes.push({
                  ...item,
                  relatorio: "Avaliação Energética",
                });
              else newRes.push({ ...item, relatorio: "Acupuntura" });
            });
          });
          const rel = newRes.sort(compare);
          setRelatorios(rel);
          setPages(Math.ceil(rel.length / 10));
        });
    }
  }

  useEffect(() => {
    if (state.selected.id || location.state) {
      setRelat();
    } else {
      setRelat(true);
    }
  }, [state.selected, location]);

  function compare(a, b) {
    if (a.Date > b.Date) {
      return -1;
    }
    if (a.Date < b.Date) {
      return 1;
    }
    return 0;
  }

  function deletarRelatorio(relatorio, id) {
    setIsLoading(true);
    if (relatorio === "Avaliação Energética") {
      axios
        .delete(process.env.REACT_APP_API_ENDPOINT + `AvEnergetica/${id}`)
        .then((res) => {
          setIsLoading(false);
          if (!res.error) {
            toast.success("Relatório excluído com sucesso");
            setRelat(true);
          } else {
            toast.error("Erro ao criar relatório");
          }
        });
    } else {
      axios
        .delete(process.env.REACT_APP_API_ENDPOINT + `Acupuntura/${id}`)
        .then((res) => {
          setIsLoading(false);
          if (!res.error) {
            toast.success("Relatório excluído com sucesso");
            setRelat(true);
          } else {
            toast.error("Erro ao criar relatório");
          }
        });
    }
  }

  function abrirRelatorio(relatorio, id) {
    setIsLoading(true);
    if (relatorio === "Avaliação Energética") {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `AvEnergetica/${id}`)
        .then((res) => {
          setIsLoading(false);
          const AvEnergetica = res.data;
          dispatch(
            actionsModal.setCliente({
              ID: AvEnergetica.cliente,
              nome:
                AvEnergetica.Cliente.primeiroNome +
                " " +
                AvEnergetica.Cliente.sobrenome,
              email: AvEnergetica.Cliente.email,
              image:
                "https://react.semantic-ui.com/images/avatar/large/matthew.png",
            })
          );
          dispatch(
            actions.setAvEnergetica({
              duracao: AvEnergetica.duracao,
              isEdit: true,
              fisico: {
                peso: AvEnergetica.Fisico.peso,
                altura: AvEnergetica.Fisico.altura,
                pressaoArterial: AvEnergetica.Fisico.pressaoArterial,
                transpiracao: AvEnergetica.Fisico.transpiracao,
                dor: AvEnergetica.Fisico.dor,
                fezes: AvEnergetica.Fisico.fezes,
                urina: AvEnergetica.Fisico.urina,
                audicao: AvEnergetica.Fisico.audicao,
                olfato: AvEnergetica.Fisico.olfato,
                visao: AvEnergetica.Fisico.visao,
                vidaSexual: AvEnergetica.Fisico.vidaSexual,
                menstruacao: AvEnergetica.Fisico.menstruacao,
                ejaculacao: AvEnergetica.Fisico.ejaculacao,
                vidaEmocional: AvEnergetica.Fisico.vidaEmocional,
                gostoLingua: AvEnergetica.Fisico.gostoLingua,
              },
              lajin: {
                maoJoelho: AvEnergetica.Lajin.maoJoelho,
                cabecaJoelho: AvEnergetica.Lajin.cabecaJoelho,
                cabecaCanela: AvEnergetica.Lajin.cabecaCanela,
                cabecaPe: AvEnergetica.Lajin.cabecaPe,
                narizJoelho: AvEnergetica.Lajin.narizJoelho,
                narizCanela: AvEnergetica.Lajin.narizCanela,
                narizPe: AvEnergetica.Lajin.narizPe,
                queixoJoelho: AvEnergetica.Lajin.queixoJoelho,
                queixoCanela: AvEnergetica.Lajin.queixoCanela,
                queixoPe: AvEnergetica.Lajin.queixoPe,
                assinale: AvEnergetica.Lajin.assinale,
                assinaleBloqueio: AvEnergetica.Lajin.assinaleBloqueio,
                assinale2: AvEnergetica.Lajin.assinale2,
                tempo: AvEnergetica.Lajin.tempo,
                angulo: AvEnergetica.Lajin.angulo,
                rotacaoEsquerda: AvEnergetica.Lajin.rotacaoEsquerda,
                rotacaoDireita: AvEnergetica.Lajin.rotacaoDireita,
                assinale3: AvEnergetica.Lajin.assinale3,
                postura: AvEnergetica.Lajin.postura,
                posicaoJoelhos: AvEnergetica.Lajin.posicaoJoelhos,
              },
              alimentacao: {
                alimentacao: AvEnergetica.Alimentacao.alimentacao,
                sAlimentacao: AvEnergetica.Alimentacao.sAlimentacao,
                comer: AvEnergetica.Alimentacao.comer,
                sComer: AvEnergetica.Alimentacao.sComer,
                comeImportante: AvEnergetica.Alimentacao.comeImportante,
                sComeImportante: AvEnergetica.Alimentacao.sComeImportante,
              },
              interrogatorio: {
                gravida: AvEnergetica.InterrogatorioAv.gravida,
                esperaArte: AvEnergetica.InterrogatorioAv.esperaArte,
                praticouArtes: AvEnergetica.InterrogatorioAv.praticouArtes,
                movimentos: AvEnergetica.InterrogatorioAv.movimentos,
                cirurgia: AvEnergetica.InterrogatorioAv.cirurgia,
                doencas: AvEnergetica.InterrogatorioAv.doencas,
                antecedentes: AvEnergetica.InterrogatorioAv.antecedentes,
                pai: AvEnergetica.InterrogatorioAv.pai,
                mae: AvEnergetica.InterrogatorioAv.mae,
                medicacao: AvEnergetica.InterrogatorioAv.medicacao,
                lesao: AvEnergetica.InterrogatorioAv.lesao,
                dormeAcorda: AvEnergetica.InterrogatorioAv.dormeAcorda,
                sono: AvEnergetica.InterrogatorioAv.sono,
              },
            })
          );
          history.push("/VisualizarAvaliacaoEnergetica");
        });
    } else {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `Acupuntura/${id}`)
        .then((res) => {
          setIsLoading(false);
          const Acupuntura = res.data;
          dispatch(
            actionsModal.setCliente({
              id: Acupuntura.cliente,
              nome:
                Acupuntura.Cliente.primeiroNome +
                " " +
                Acupuntura.Cliente.sobrenome,
              email: Acupuntura.Cliente.email,
              image:
                "https://react.semantic-ui.com/images/avatar/large/matthew.png",
            })
          );
          dispatch(
            actionsAcupuntura.setAcupunturaForm({
              duracao: Acupuntura.duracao,
              isEdit: true,
              sessao: {
                queixaPrincipal: Acupuntura.Sessao.queixaPrincipal,
                queixaSecundaria: Acupuntura.Sessao.queixaSecundaria,
                observacao: Acupuntura.Sessao.observacao,
              },
              inspecao: {
                lingua: Acupuntura.Inspecao.lingua,
                hma: Acupuntura.Inspecao.hma,
              },
              compleicao: {
                geral: Acupuntura.Compleicao.geral,
                aspectoGeral: Acupuntura.Compleicao.aspectoGeral,
              },
              corPele: {
                corPele: Acupuntura.CorPele.corPele,
                expressaoRosto: Acupuntura.CorPele.expressaoRosto,
                cabecaCabelos: Acupuntura.CorPele.cabecaCabelos,
                olhos: Acupuntura.CorPele.olhos,
                nariz: Acupuntura.CorPele.nariz,
                orelhas: Acupuntura.CorPele.orelhas,
                labios: Acupuntura.CorPele.labios,
                pele: Acupuntura.CorPele.pele,
                observacao: Acupuntura.CorPele.observacao,
              },
              auscultacao: {
                fala: Acupuntura.Auscultacao.fala,
                observacaoFala: Acupuntura.Auscultacao.observacaoFala,
                respiracao: Acupuntura.Auscultacao.respiracao,
                observacaoRespiracao:
                  Acupuntura.Auscultacao.observacaoRespiracao,
              },
              interrogatorio: {
                transpiracao: Acupuntura.Interrogatorio.transpiracao,
                obTranspiracao: Acupuntura.Interrogatorio.obTranspiracao,
                observacaoFala: Acupuntura.Interrogatorio.observacaoFala,
                sono: Acupuntura.Interrogatorio.sono,
                obSono: Acupuntura.Interrogatorio.obSono,
                emocoes: Acupuntura.Interrogatorio.emocoes,
                obEmocoes: Acupuntura.Interrogatorio.obEmocoes,
                alimentacao: Acupuntura.Interrogatorio.alimentacao,
                obAlimentacao: Acupuntura.Interrogatorio.obAlimentacao,
                sabores: Acupuntura.Interrogatorio.sabores,
                obSabores: Acupuntura.Interrogatorio.obSabores,
                sede: Acupuntura.Interrogatorio.sede,
                obSede: Acupuntura.Interrogatorio.obSede,
                disfuncoes: Acupuntura.Interrogatorio.disfuncoes,
                obDisfuncoes: Acupuntura.Interrogatorio.obDisfuncoes,
                excrecoes: Acupuntura.Interrogatorio.excrecoes,
                obExcrecoes: Acupuntura.Interrogatorio.obExcrecoes,
                menstruacao: Acupuntura.Interrogatorio.menstruacao,
                obMenstruacao: Acupuntura.Interrogatorio.obMenstruacao,
              },
              orgaoSentidos: {
                olhosVisao: Acupuntura.OrgaoSentido.olhosVisao,
                ouvidosAudicao: Acupuntura.OrgaoSentido.ouvidosAudicao,
                narizOlfato: Acupuntura.OrgaoSentido.narizOlfato,
                tato: Acupuntura.OrgaoSentido.tato,
                bocaGosto: Acupuntura.OrgaoSentido.bocaGosto,
                observacao: Acupuntura.OrgaoSentido.observacao,
              },
              algias: {
                coluna: Acupuntura.Algia.coluna,
                obColuna: Acupuntura.Algia.obColuna,
                doresMusculares: Acupuntura.Algia.doresMusculares,
                doresArticulares: Acupuntura.Algia.doresArticulares,
                obdoresArticulares: Acupuntura.Algia.obdoresArticulares,
                abdomen: Acupuntura.Algia.abdomen,
                obAbdomen: Acupuntura.Algia.obAbdomen,
                torax: Acupuntura.Algia.torax,
                obTorax: Acupuntura.Algia.obTorax,
                doresCabeca: Acupuntura.Algia.doresCabeca,
                obDoresCabeca: Acupuntura.Algia.obDoresCabeca,
              },
              palpacao: {
                metalP: Acupuntura.Palpacao.metalP,
                fogoC: Acupuntura.Palpacao.fogoC,
                terraBP: Acupuntura.Palpacao.terraBP,
                madF: Acupuntura.Palpacao.madF,
                fogoCS: Acupuntura.Palpacao.fogoCS,
                aguaR: Acupuntura.Palpacao.aguaR,
                metalIG: Acupuntura.Palpacao.metalIG,
                fogoID: Acupuntura.Palpacao.fogoID,
                terraE: Acupuntura.Palpacao.terraE,
                madVB: Acupuntura.Palpacao.madVB,
                fogoTA: Acupuntura.Palpacao.fogoTA,
                aguaB: Acupuntura.Palpacao.aguaB,
                pele: Acupuntura.Palpacao.pele,
                temperatura: Acupuntura.Palpacao.temperatura,
                abdome: Acupuntura.Palpacao.abdome,
                sensibilidade: Acupuntura.Palpacao.sensibilidade,
              },
              cincoElementos: {
                aguaSize: Acupuntura.CincoElemento.aguaSize,
                fogoSize: Acupuntura.CincoElemento.fogoSize,
                madeiraSize: Acupuntura.CincoElemento.madeiraSize,
                metalSize: Acupuntura.CincoElemento.metalSize,
                terraSize: Acupuntura.CincoElemento.terraSize,
                rotation1: Number(Acupuntura.CincoElemento.rotation1),
                rotation2: Number(Acupuntura.CincoElemento.rotation2),
                rotation3: Number(Acupuntura.CincoElemento.rotation3),
                rotation4: Number(Acupuntura.CincoElemento.rotation4),
                rotation5: Number(Acupuntura.CincoElemento.rotation5),
                observacao: Acupuntura.CincoElemento.observacao,
              },
              acupuntura: {
                pontos: JSON.parse(Acupuntura.pontos),
              },
              lingua: {
                opacity1: Acupuntura.Lingua.opacity1,
                opacity2: Acupuntura.Lingua.opacity2,
                opacity3: Acupuntura.Lingua.opacity3,
                opacity4: Acupuntura.Lingua.opacity4,
                opacity5: Acupuntura.Lingua.opacity5,
                opacity6: Acupuntura.Lingua.opacity6,
                lingua1: Acupuntura.Lingua.lingua1,
                lingua2: Acupuntura.Lingua.lingua2,
                lingua3: Acupuntura.Lingua.lingua3,
                lingua4: Acupuntura.Lingua.lingua4,
                lingua5: Acupuntura.Lingua.lingua5,
                lingua6: Acupuntura.Lingua.lingua6,
              },
            })
          );
          history.push("/VisualizarAcupuntura");
        });
    }
  }

  const renderPDF = (cliente) => {
    setIsLoading(true);
    if (cliente.relatorio === "Avaliação Energética") {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `AvEnergetica/${cliente.ID}`)
        .then((res) => {
          setIsLoading(false);
          setData(res.data);
        });
    } else {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + `Acupuntura/${cliente.ID}`)
        .then((res) => {
          setIsLoading(false);
          setData(res.data);
        });
    }
  };

  const pdfButton = (cliente) => {
    if (data) {
      if (cliente.relatorio === "Acupuntura") {
        return (
          <PDFDownloadLink
            document={<AcupunturaPDF data={data} />}
            fileName={`Acupuntura_${cliente.Cliente.PrimeiroNome}_${
              cliente.Cliente.Sobrenome
            }_${moment(cliente.Date).format("L").replace("/", "_")}.pdf`}
            style={{
              color: "#fff",
            }}
          >
            Download Pdf{" "}
            {isLoading ? <Loader active inline size="mini" /> : null}
          </PDFDownloadLink>
        );
      } else {
        return (
          <PDFDownloadLink
            document={<AvEnergeticaPDF data={data} />}
            fileName={`AvEnergetica_${cliente.Cliente.PrimeiroNome}_${
              cliente.Cliente.Sobrenome
            }_${moment(cliente.Date).format("L").replace("/", "_")}.pdf`}
            style={{
              color: "#fff",
            }}
          >
            Download PDF{" "}
            {isLoading ? <Loader active inline size="mini" /> : null}
          </PDFDownloadLink>
        );
      }
    } else {
      return (
        <>Gerar PDF {isLoading ? <Loader active inline size="mini" /> : null}</>
      );
    }
  };

  if (relatorios.length > 0) {
    const relatoriosRows = relatorios.map((r, i) => {
      if (activePage * 10 - 10 <= i && i < activePage * 10) {
        return (
          <Table.Row>
            <Table.Cell>{`${r.Cliente.PrimeiroNome} ${r.Cliente.Sobrenome}`}</Table.Cell>
            <Table.Cell>{r.relatorio}</Table.Cell>
            <Table.Cell>{moment(r.Date).format("LLL")}</Table.Cell>
            <Table.Cell>
              <Popup
                on="click"
                onClose={() => setData(false)}
                content={
                  <List selection>
                    <List.Item>
                      <Button
                        color="grey"
                        size="mini"
                        fluid
                        compact
                        onClick={() => renderPDF(r)}
                      >
                        <Icon name="file" />
                        {pdfButton(r)}
                      </Button>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="yellow"
                        size="mini"
                        fluid
                        compact
                        onClick={() => abrirRelatorio(r.relatorio, r.ID)}
                        disabled={isLoading}
                      >
                        <Icon name="edit" />
                        Abrir{" "}
                        {isLoading ? (
                          <Loader active inline size="mini" />
                        ) : null}
                      </Button>
                    </List.Item>
                    <List.Item>
                      <Button
                        color="red"
                        size="mini"
                        fluid
                        compact
                        onClick={() => deletarRelatorio(r.relatorio, r.ID)}
                        disabled={isLoading}
                      >
                        <Icon name="trash" />
                        Deletar{" "}
                        {isLoading ? (
                          <Loader active inline size="mini" />
                        ) : null}
                      </Button>
                    </List.Item>
                  </List>
                }
                trigger={<Button icon="settings" />}
              />
            </Table.Cell>
          </Table.Row>
        );
      }
    });

    tableRelatorios = (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Cliente</Table.HeaderCell>
            <Table.HeaderCell>Relatorio</Table.HeaderCell>
            <Table.HeaderCell>Data</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{relatoriosRows}</Table.Body>
      </Table>
    );
  }

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          <Message
            icon="inbox"
            header="Consultar Relatórios"
            //content="Get the best news in your e-mail every day."
          />
          <Search
            placeholder="Pesquise o cliente"
            loading={isLoading}
            onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(handleSearchChange, 500, {
              leading: true,
            })}
            results={state.results}
            value={state.value}
          />
          {tableRelatorios}
          {relatorios.length > 0 ? (
            <Pagination
              defaultActivePage={1}
              totalPages={pages}
              onPageChange={(event, data) => setActivePage(data.activePage)}
            />
          ) : null}
        </div>
        <ToastContainer position="bottom-right" />
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
