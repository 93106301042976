import React from "react";
import { Message, Breadcrumb, Table, Icon, Button } from "semantic-ui-react";
import Sidebar from "../../assets/Sidebar";

export default function ConteudoEducacional() {
  const sections = [{ key: "Home", content: "Home", link: true }];

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[7] === "1") {
      return true;
    } else {
      return false;
    }
  }

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          <Message
            icon="book"
            header="Conteúdo Educacional"
            //content="Get the best news in your e-mail every day."
          />
          <Button>
            <Icon name="folder" />
            Nova Pasta
          </Button>
          <Button>
            <Icon name="file" />
            Novo Arquivo
          </Button>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Breadcrumb icon="right angle" sections={sections} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <Icon name="folder" /> node_modules
                </Table.Cell>
                <Table.Cell collapsing textAlign="right">
                  10 hours ago
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Icon name="folder" /> test
                </Table.Cell>
                <Table.Cell textAlign="right">10 hours ago</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
