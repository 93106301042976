import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "./Forms/redux/actions";

function ModalExampleBasic({ sendForm }) {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        <Button primary className="btnSelected" onClick={() => setOpen(true)}>
          Terminar sessão
        </Button>
      }
    >
      <Header icon>
        <Icon name="save" />
        Terminar sessão
      </Header>
      <Modal.Content>
        <p>Você tem certeza que deseja terminar esta sessão?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={() => setOpen(false)}>
          <Icon name="remove" /> Não
        </Button>
        <Button
          color="green"
          inverted
          onClick={() => {
            setOpen(false);
            sendForm();
            dispatch(actions.clearStateAcupuntura());
            history.push("/Home");
          }}
        >
          <Icon name="checkmark" /> Sim
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ModalExampleBasic;
