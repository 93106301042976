import React from "react";
import { Form } from "semantic-ui-react";
import "../index.css";

export default function ProgramaTreinamento() {
  return (
    <div className="divForm">
      <Form>
        <Form.Field>
          <label>Nome</label>
          <input placeholder="Nome" />
        </Form.Field>
        <Form.Field>
          <label>Idade</label>
          <input placeholder="Idade" />
        </Form.Field>
        <Form.Field>
          <label>Objetivos</label>
          <input placeholder="Objetivos" />
        </Form.Field>
        <Form.Field>
          <label>Observações</label>
          <input placeholder="Observações" />
        </Form.Field>
      </Form>
    </div>
  );
}
