import React, { useState, createRef } from "react";
import {
  Menu,
  Message,
  Grid,
  Card,
  Image,
  Sticky,
  Ref,
  Rail,
  Form,
  Header,
} from "semantic-ui-react";
import Timer from "react-compound-timer";
import axios from "axios";
import Sidebar from "../../assets/Sidebar";
import Fisico from "./Forms/Fisico";
import Interrogatorio from "./Forms/Interrogatorio";
import Alimentacao from "./Forms/Alimentacao";
import Lajin from "./Forms/Lajin";
import Modal from "./modal";
import * as moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";

export default function Avaliacao() {
  const avEnergetica = useSelector((state) => state.avEnergetica);
  const { cliente } = useSelector((state) => state.cliente);
  const [activeItem, setActiveItem] = useState("Físico");
  const [initialTime, setInitialTime] = useState(
    avEnergetica.duracao ? avEnergetica.duracao : 0
  );
  const [isEdit, setIsEdit] = useState(avEnergetica.isEdit);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [duracao, setDuracao] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const contextRef = createRef();
  const dispatch = useDispatch();

  async function sendForm() {
    setLoading(true);
    const now = moment().format();
    const c = {
      cliente: cliente.id,
      duracao: duracao,
      date: now,
      avEnergetica: avEnergetica,
    };
    const res = await axios.post(
      process.env.REACT_APP_API_ENDPOINT + `AvEnergetica`,
      c
    );
    setLoading(false);
    return res;
  }

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[9] === "1") {
      return true;
    } else {
      return false;
    }
  }

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Message
                  icon="inbox"
                  header="Avaliação Energética"
                  //content="Get the best news in your e-mail every day."
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Ref innerRef={contextRef}>
                  <Rail internal position="left" attached className="railLeft">
                    <Sticky
                      context={contextRef}
                      offset={80}
                      style={{ zIndex: 0 }}
                    >
                      <Card>
                        <Card.Content>
                          <Image
                            floated="right"
                            size="mini"
                            src="https://react.semantic-ui.com/images/avatar/large/matthew.png"
                          />
                          <Card.Header>{cliente.nome}</Card.Header>
                          <Card.Meta>{cliente.email}</Card.Meta>
                          <Card.Description>
                            <strong>Tempo:</strong>&nbsp;
                            <Timer
                              initialTime={initialTime}
                              startImmediately={!isEdit}
                            >
                              {({ getTime }) => (
                                <React.Fragment>
                                  <Timer.Minutes /> : &nbsp;
                                  <Timer.Seconds />
                                  {setDuracao(getTime())}
                                </React.Fragment>
                              )}
                            </Timer>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                      <Menu pointing secondary vertical>
                        <Menu.Item
                          name="Físico"
                          active={activeItem === "Físico"}
                          onClick={() => setActiveItem("Físico")}
                        />
                        <Menu.Item
                          name="Interrogatório"
                          active={activeItem === "Interrogatório"}
                          onClick={() => setActiveItem("Interrogatório")}
                        />
                        <Menu.Item
                          name="Alimentação"
                          active={activeItem === "Alimentação"}
                          onClick={() => setActiveItem("Alimentação")}
                        />
                        <Menu.Item
                          name="Lajin"
                          active={activeItem === "Lajin"}
                          onClick={() => setActiveItem("Lajin")}
                        />
                      </Menu>
                      {!isEdit ? <Modal sendForm={sendForm} /> : null}
                    </Sticky>
                  </Rail>
                </Ref>
              </Grid.Column>
              <Grid.Column width={13} style={{ paddingLeft: "5rem" }}>
                <Header as="h2">{activeItem}</Header>
                <Form>
                  {(() => {
                    switch (activeItem) {
                      case "Físico":
                        return <Fisico />;
                      case "Interrogatório":
                        return <Interrogatorio />;
                      case "Alimentação":
                        return <Alimentacao />;
                      case "Lajin":
                        return <Lajin />;
                      default:
                        return null;
                    }
                  })()}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
