import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Form, TextArea, Input, Grid } from "semantic-ui-react";

export default function Palpacao() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [metalP, setMetalP] = useState(acupuntura.palpacao.metalP);
  const [fogoC, setFogoC] = useState(acupuntura.palpacao.fogoC);
  const [terraBP, setTerraBP] = useState(acupuntura.palpacao.terraBP);
  const [madF, setMadF] = useState(acupuntura.palpacao.madF);
  const [fogoCS, setFogoCS] = useState(acupuntura.palpacao.fogoCS);
  const [aguaR, setAguaR] = useState(acupuntura.palpacao.aguaR);
  const [metalIG, setMetalIG] = useState(acupuntura.palpacao.metalIG);
  const [fogoID, setFogoID] = useState(acupuntura.palpacao.fogoID);
  const [terraE, setTerraE] = useState(acupuntura.palpacao.terraE);
  const [madVB, setMadVB] = useState(acupuntura.palpacao.madVB);
  const [fogoTA, setFogoTA] = useState(acupuntura.palpacao.fogoTA);
  const [aguaB, setAguaB] = useState(acupuntura.palpacao.aguaB);
  const [pele, setPele] = useState(acupuntura.palpacao.pele);
  const [temperatura, setTemperatura] = useState(
    acupuntura.palpacao.temperatura
  );
  const [abdome, setAbdome] = useState(acupuntura.palpacao.abdome);
  const [sensibilidade, setSensibilidade] = useState(
    acupuntura.palpacao.sensibilidade
  );

  useEffect(() => {
    dispatch(
      actions.setPalpacao({
        metalP: metalP,
        fogoC: fogoC,
        terraBP: terraBP,
        madF: madF,
        fogoCS: fogoCS,
        aguaR: aguaR,
        metalIG: metalIG,
        fogoID: fogoID,
        terraE: terraE,
        madVB: madVB,
        fogoTA: fogoTA,
        aguaB: aguaB,
        pele: pele,
        temperatura: temperatura,
        abdome: abdome,
        sensibilidade: sensibilidade,
      })
    );
  }, [
    dispatch,
    metalP,
    fogoC,
    terraBP,
    madF,
    fogoCS,
    aguaR,
    metalIG,
    fogoID,
    terraE,
    madVB,
    fogoTA,
    aguaB,
    pele,
    temperatura,
    abdome,
    sensibilidade,
  ]);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>Direita (Yin)</label>
            <Form.Field inline>
              <label>Metal - P</label>
              <Input
                fluid
                value={metalP}
                onChange={(e) => setMetalP(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Terra - BP</label>
              <Input
                fluid
                value={fogoC}
                onChange={(e) => setFogoC(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Fogo - CS</label>
              <Input
                fluid
                value={terraBP}
                onChange={(e) => setTerraBP(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>Esquerda (Yin)</label>
            <Form.Field inline>
              <label>Fogo - C</label>
              <Input
                fluid
                value={madF}
                onChange={(e) => setMadF(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Mad. - F</label>
              <Input
                fluid
                value={fogoCS}
                onChange={(e) => setFogoCS(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Água - R</label>
              <Input
                fluid
                value={aguaR}
                onChange={(e) => setAguaR(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <label>Direita (Yang)</label>
            <Form.Field inline>
              <label>Metal - IG</label>
              <Input
                fluid
                value={metalIG}
                onChange={(e) => setMetalIG(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Terra - E</label>
              <Input
                fluid
                value={fogoID}
                onChange={(e) => setFogoID(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Fogo - TA</label>
              <Input
                fluid
                value={terraE}
                onChange={(e) => setTerraE(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <label>Esquerda (Yang)</label>
            <Form.Field inline>
              <label>Fogo - ID</label>
              <Input
                fluid
                value={madVB}
                onChange={(e) => setMadVB(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Mad. - VB</label>
              <Input
                fluid
                value={fogoTA}
                onChange={(e) => setFogoTA(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
            <Form.Field inline>
              <label>Água - B</label>
              <Input
                fluid
                value={aguaB}
                onChange={(e) => setAguaB(e.target.value)}
                maxLength="255"
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Form.Field>
        <label>Pele (temperatura, umidade, edemas)</label>
        <TextArea
          value={pele}
          onChange={(e) => setPele(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Temperatura de mãos e pés</label>
        <TextArea
          value={temperatura}
          onChange={(e) => setTemperatura(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>
          Abdome (umidade de pele, dores à palpação, grau de firmeza, edemas,
          massas)
        </label>
        <TextArea
          value={abdome}
          onChange={(e) => setAbdome(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
      <Form.Field>
        <label>Sensibilidade nos pontos e no trajeto dos meridianos</label>
        <TextArea
          value={sensibilidade}
          onChange={(e) => setSensibilidade(e.target.value)}
          maxLength="400"
        />
      </Form.Field>
    </React.Fragment>
  );
}
