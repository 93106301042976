const avEnergetica = (
  state = {
    duracao: 0,
    isEdit: false,
    fisico: {
      peso: "",
      altura: "",
      pressaoArterial: "",
      transpiracao: "",
      dor: "",
      fezes: "",
      urina: "",
      audicao: "",
      olfato: "",
      visao: "",
      vidaSexual: "",
      menstruacao: "",
      ejaculacao: "",
      vidaEmocional: "",
      gostoLingua: "",
    },
    lajin: {
      maoJoelho: "",
      cabecaJoelho: "",
      cabecaCanela: "",
      cabecaPe: "",
      narizJoelho: "",
      narizCanela: "",
      narizPe: "",
      queixoJoelho: "",
      queixoCanela: "",
      queixoPe: "",
      assinale: "",
      assinaleBloqueio: "",
      assinale2: "",
      tempo: "",
      angulo: "",
      rotacaoEsquerda: "",
      rotacaoDireita: "",
      assinale3: "",
      postura: "",
      posicaoJoelhos: "",
    },
    alimentacao: {
      alimentacao: "",
      sAlimentacao: "",
      comer: "",
      sComer: "",
      comeImportante: "",
      sComeImportante: "",
    },
    interrogatorio: {
      gravida: "",
      esperaArte: "",
      praticouArtes: "",
      movimentos: "",
      cirurgia: "",
      doencas: "",
      antecedentes: "",
      pai: "",
      mae: "",
      medicacao: "",
      lesao: "",
      dormeAcorda: "",
      sono: "",
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_FISICO":
      return {
        ...state,
        fisico: action.payload,
      };
    case "SET_LAJIN":
      return {
        ...state,
        lajin: action.payload,
      };
    case "SET_ALIMENTACAO":
      return {
        ...state,
        alimentacao: action.payload,
      };
    case "SET_INTERROGATORIO":
      return {
        ...state,
        interrogatorio: action.payload,
      };
    case "CLEAR_STATE_AVALIACAO":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_AVENERGETICA":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default avEnergetica;
