function soNumeros(d) {
  return d.replace(/\D/g, "");
}

export const telefoneMask = (v) => {
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
};

export const cepMask = (d) => {
  d = soNumeros(d);
  d = d.replace(/^(\d{5})(\d)/, "$1-$2");
  return d;
};

export const dataMask = (d) => {
  d = soNumeros(d);
  d = d.replace(/(\d{2})(\d)/, "$1/$2");
  d = d.replace(/(\d{2})(\d)/, "$1/$2");
  return d;
};
