import _ from "lodash";
import React, { useState, useEffect } from "react";
import "./main.css";
import {
  Button,
  Modal,
  Menu,
  Icon,
  Form,
  Grid,
  Search,
  Checkbox,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import actions from "./redux/actions";
import actionsAvEnergetica from "../../pages/Avaliacao/Forms/redux/actions";
import actionsAcupuntura from "../../pages/Acupuntura/Forms/redux/actions";

const initialState = { isLoading: false, results: [], selected: {}, value: "" };

export default function ModalWindows() {
  const location = useLocation();
  let history = useHistory();
  const [state, setState] = useState(initialState);
  const [sessao, setSessao] = useState("Acupuntura");
  const [modalOpen, setModalOpen] = useState(false);
  const [source, setSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newTreatment, setNewTreatment] = useState(false);
  const dispatch = useDispatch();

  const handleResultSelect = (e, { result }) =>
    setState({
      isLoading: false,
      results: [],
      selected: {
        id: result.id,
        title: result.title,
        description: result.description,
        image: result.image,
      },
      value: result.title,
    });

  const handleSearchChange = (e, { value }) => {
    setState({ isLoading: true, results: [], selected: {}, value: value });
  };

  useEffect(() => {
    if (location.pathname !== "/Acupuntura")
      dispatch(actions.setNewTreatment(false));
    getClientes();
  }, []);

  function getClientes() {
    setIsLoading(true);
    return axios
      .get(process.env.REACT_APP_API_ENDPOINT + `Clientes`)
      .then((res) => {
        setIsLoading(false);
        let clientes = [];

        res.data.map((cliente) => {
          const c = {
            id: cliente.id,
            title: cliente.primeiroNome + " " + cliente.sobrenome,
            description: cliente.email,
            image:
              "https://s3.amazonaws.com/uifaces/faces/twitter/stefanotirloni/128.jpg",
          };
          clientes.push(c);
        });
        setSource(clientes);
      });
  }

  useEffect(() => {
    if (state.value.length < 1) return setState(initialState);

    const re = new RegExp(_.escapeRegExp(state.value), "i");
    const isMatch = (result) => re.test(result.title);

    setState({
      isLoading: false,
      results: _.filter(source, isMatch),
      selected: state.selected,
      value: state.value,
    });
  }, [state.value, source]);

  function comecarSessao() {
    dispatch(actionsAvEnergetica.clearStateAvaliacao());
    dispatch(actionsAcupuntura.clearStateAcupuntura());
    dispatch(
      actions.setCliente({
        id: state.selected.id,
        nome: state.selected.title,
        email: state.selected.description,
        image: state.selected.image,
      })
    );
    if (sessao === "Acupuntura" && state.value != "") {
      history.push("/Acupuntura");
    } else if (state.value != "") {
      history.push("/AvaliacaoEnergetica");
    }
  }

  function handleNewTreatmentChange() {
    setNewTreatment(!newTreatment);
    dispatch(actions.setNewTreatment(!newTreatment));
  }

  return (
    <Modal
      trigger={
        <Menu.Item as="a">
          <Button
            className="btnIniciar"
            primary
            fluid
            onClick={() => setModalOpen(true)}
          >
            <Icon name="world" />
            Iniciar Sessão
          </Button>
        </Menu.Item>
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <Modal.Header>Iniciar Sessão</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <Search
                placeholder="Pesquise o cliente"
                loading={isLoading}
                onResultSelect={handleResultSelect}
                onSearchChange={_.debounce(handleSearchChange, 500, {
                  leading: true,
                })}
                results={state.results}
                value={state.value}
              />
            </Form.Field>
            <Grid>
              <Grid.Row centered>
                <Button
                  className={sessao === "Acupuntura" ? "btnSelected" : false}
                  onClick={() => setSessao("Acupuntura")}
                >
                  Acupuntura
                </Button>
                <Button
                  className={sessao === "AvEnergetica" ? "btnSelected" : false}
                  onClick={() => setSessao("AvEnergetica")}
                >
                  Avaliação Energética
                </Button>
              </Grid.Row>
              <Grid.Row>
                <Button
                  type="submit"
                  className="btnSelected"
                  onClick={comecarSessao}
                >
                  Começar
                </Button>
                <Form.Field>
                  <Checkbox
                    label="Novo Tratamento"
                    value={newTreatment}
                    onChange={handleNewTreatmentChange}
                  />
                </Form.Field>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
