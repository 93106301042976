import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import actions from "./redux/actions";

export default function Lajin() {
  const avEnergetica = useSelector((state) => state.avEnergetica);
  const [maoJoelho, setMaoJoelho] = useState(avEnergetica.lajin.maoJoelho);
  const [cabecaJoelho, setCabecaJoelho] = useState(
    avEnergetica.lajin.cabecaJoelho
  );
  const [cabecaCanela, setCabecaCanela] = useState(
    avEnergetica.lajin.cabecaCanela
  );
  const [cabecaPe, setCabecaPe] = useState(avEnergetica.lajin.cabecaPe);
  const [narizJoelho, setNarizJoelho] = useState(
    avEnergetica.lajin.narizJoelho
  );
  const [narizCanela, setNarizCanela] = useState(
    avEnergetica.lajin.narizCanela
  );
  const [narizPe, setNarizPe] = useState(avEnergetica.lajin.narizPe);
  const [queixoJoelho, setQueixoJoelho] = useState(
    avEnergetica.lajin.queixoJoelho
  );
  const [queixoCanela, setQueixoCanela] = useState(
    avEnergetica.lajin.queixoCanela
  );
  const [queixoPe, setQueixoPe] = useState(avEnergetica.lajin.queixoPe);
  const [assinale, setAssinale] = useState(avEnergetica.lajin.assinale);
  const [assinaleBloqueio, setAssinaleBloqueio] = useState(
    avEnergetica.lajin.assinaleBloqueio
  );
  const [assinale2, setAssinale2] = useState(avEnergetica.lajin.assinale2);
  const [tempo, setTempo] = useState(avEnergetica.lajin.tempo);
  const [angulo, setAngulo] = useState(avEnergetica.lajin.angulo);
  const [rotacaoEsquerda, setRotacaoEsquerda] = useState(
    avEnergetica.lajin.rotacaoEsquerda
  );
  const [rotacaoDireita, setRotacaoDireita] = useState(
    avEnergetica.lajin.rotacaoDireita
  );
  const [assinale3, setAssinale3] = useState(avEnergetica.lajin.assinale3);
  const [postura, setPostura] = useState(avEnergetica.lajin.postura);
  const [posicaoJoelhos, setPosicaoJoelhos] = useState(
    avEnergetica.lajin.posicaoJoelhos
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.setLajin({
        maoJoelho: maoJoelho,
        cabecaJoelho: cabecaJoelho,
        cabecaCanela: cabecaCanela,
        cabecaPe: cabecaPe,
        narizJoelho: narizJoelho,
        narizCanela: narizCanela,
        narizPe: narizPe,
        queixoJoelho: queixoJoelho,
        queixoCanela: queixoCanela,
        queixoPe: queixoPe,
        assinale: assinale,
        assinaleBloqueio: assinaleBloqueio,
        assinale2: assinale2,
        tempo: tempo,
        angulo: angulo,
        rotacaoEsquerda: rotacaoEsquerda,
        rotacaoDireita: rotacaoDireita,
        assinale3: assinale3,
        postura: postura,
        posicaoJoelhos: posicaoJoelhos,
      })
    );
  }, [
    dispatch,
    maoJoelho,
    cabecaJoelho,
    cabecaCanela,
    cabecaPe,
    narizJoelho,
    narizCanela,
    narizPe,
    queixoJoelho,
    queixoCanela,
    queixoPe,
    assinale,
    assinaleBloqueio,
    assinale2,
    tempo,
    angulo,
    rotacaoEsquerda,
    rotacaoDireita,
    assinale3,
    postura,
    posicaoJoelhos,
  ]);

  return (
    <>
      <Form.Group>
        <label>Mãos no joelho</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={maoJoelho === "Esquerda"}
          onChange={(e, { name, value }) => setMaoJoelho(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={maoJoelho === "Direita"}
          onChange={(e, { name, value }) => setMaoJoelho(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={maoJoelho === "Ambos"}
          onChange={(e, { name, value }) => setMaoJoelho(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={maoJoelho === "Não chega"}
          onChange={(e, { name, value }) => setMaoJoelho(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Cabeça no joelho</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={cabecaJoelho === "Esquerda"}
          onChange={(e, { name, value }) => setCabecaJoelho(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={cabecaJoelho === "Direita"}
          onChange={(e, { name, value }) => setCabecaJoelho(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={cabecaJoelho === "Ambos"}
          onChange={(e, { name, value }) => setCabecaJoelho(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={cabecaJoelho === "Não chega"}
          onChange={(e, { name, value }) => setCabecaJoelho(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Cabeça na canela</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={cabecaCanela === "Esquerda"}
          onChange={(e, { name, value }) => setCabecaCanela(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={cabecaCanela === "Direita"}
          onChange={(e, { name, value }) => setCabecaCanela(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={cabecaCanela === "Ambos"}
          onChange={(e, { name, value }) => setCabecaCanela(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={cabecaCanela === "Não chega"}
          onChange={(e, { name, value }) => setCabecaCanela(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Cabeça no pé</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={cabecaPe === "Esquerda"}
          onChange={(e, { name, value }) => setCabecaPe(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={cabecaPe === "Direita"}
          onChange={(e, { name, value }) => setCabecaPe(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={cabecaPe === "Ambos"}
          onChange={(e, { name, value }) => setCabecaPe(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={cabecaPe === "Não chega"}
          onChange={(e, { name, value }) => setCabecaPe(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Nariz no joelho</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={narizJoelho === "Esquerda"}
          onChange={(e, { name, value }) => setNarizJoelho(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={narizJoelho === "Direita"}
          onChange={(e, { name, value }) => setNarizJoelho(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={narizJoelho === "Ambos"}
          onChange={(e, { name, value }) => setNarizJoelho(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={narizJoelho === "Não chega"}
          onChange={(e, { name, value }) => setNarizJoelho(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Nariz na canela</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={narizCanela === "Esquerda"}
          onChange={(e, { name, value }) => setNarizCanela(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={narizCanela === "Direita"}
          onChange={(e, { name, value }) => setNarizCanela(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={narizCanela === "Ambos"}
          onChange={(e, { name, value }) => setNarizCanela(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={narizCanela === "Não chega"}
          onChange={(e, { name, value }) => setNarizCanela(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Nariz no pé</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={narizPe === "Esquerda"}
          onChange={(e, { name, value }) => setNarizPe(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={narizPe === "Direita"}
          onChange={(e, { name, value }) => setNarizPe(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={narizPe === "Ambos"}
          onChange={(e, { name, value }) => setNarizPe(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={narizPe === "Não chega"}
          onChange={(e, { name, value }) => setNarizPe(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Queixo no joelho</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={queixoJoelho === "Esquerda"}
          onChange={(e, { name, value }) => setQueixoJoelho(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={queixoJoelho === "Direita"}
          onChange={(e, { name, value }) => setQueixoJoelho(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={queixoJoelho === "Ambos"}
          onChange={(e, { name, value }) => setQueixoJoelho(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={queixoJoelho === "Não chega"}
          onChange={(e, { name, value }) => setQueixoJoelho(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Queixo na canela</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={queixoCanela === "Esquerda"}
          onChange={(e, { name, value }) => setQueixoCanela(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={queixoCanela === "Direita"}
          onChange={(e, { name, value }) => setQueixoCanela(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={queixoCanela === "Ambos"}
          onChange={(e, { name, value }) => setQueixoCanela(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={queixoCanela === "Não chega"}
          onChange={(e, { name, value }) => setQueixoCanela(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Queixo no pé</label>
        <Form.Radio
          label="Esquerda"
          value="Esquerda"
          checked={queixoPe === "Esquerda"}
          onChange={(e, { name, value }) => setQueixoPe(value)}
        />
        <Form.Radio
          label="Direita"
          value="Direita"
          checked={queixoPe === "Direita"}
          onChange={(e, { name, value }) => setQueixoPe(value)}
        />
        <Form.Radio
          label="Ambos"
          value="Ambos"
          checked={queixoPe === "Ambos"}
          onChange={(e, { name, value }) => setQueixoPe(value)}
        />
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={queixoPe === "Não chega"}
          onChange={(e, { name, value }) => setQueixoPe(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Assinale</label>
        <Form.Radio
          label="Não chega"
          value="Não chega"
          checked={assinale === "Não chega"}
          onChange={(e, { name, value }) => setAssinale(value)}
        />
        <Form.Radio
          label="Dedos"
          value="Dedos"
          checked={assinale === "Dedos"}
          onChange={(e, { name, value }) => setAssinale(value)}
        />
        <Form.Radio
          label="Palmas"
          value="Palmas"
          checked={assinale === "Palmas"}
          onChange={(e, { name, value }) => setAssinale(value)}
        />
        <Form.Radio
          label="Cotovelo"
          value="Cotovelo"
          checked={assinale === "Cotovelo"}
          onChange={(e, { name, value }) => setAssinale(value)}
        />
        <Form.Radio
          label="Cabeça"
          value="Cabeça"
          checked={assinale === "Cabeça"}
          onChange={(e, { name, value }) => setAssinale(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Assinale o local que apresenta mais bloqueio</label>
        <Form.Radio
          label="Cervical"
          value="Cervical"
          checked={assinaleBloqueio === "Cervical"}
          onChange={(e, { name, value }) => setAssinaleBloqueio(value)}
        />
        <Form.Radio
          label="Torácica"
          value="Torácica"
          checked={assinaleBloqueio === "Torácica"}
          onChange={(e, { name, value }) => setAssinaleBloqueio(value)}
        />
        <Form.Radio
          label="Lombar"
          value="Lombar"
          checked={assinaleBloqueio === "Lombar"}
          onChange={(e, { name, value }) => setAssinaleBloqueio(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Assinale</label>
        <Form.Radio
          label="Não tem extensão total dos cotovelos"
          value="Não tem extensão total dos cotovelos"
          checked={assinale2 === "Não tem extensão total dos cotovelos"}
          onChange={(e, { name, value }) => setAssinale2(value)}
        />
        <Form.Radio
          label="Fica a frente da cabeça"
          value="Fica a frente da cabeça"
          checked={assinale2 === "Fica a frente da cabeça"}
          onChange={(e, { name, value }) => setAssinale2(value)}
        />
        <Form.Radio
          label="Extensão total dos cotovelos e não passa a linha da cabeça"
          value="Extensão total dos cotovelos e não passa a linha da cabeça"
          checked={
            assinale2 ===
            "Extensão total dos cotovelos e não passa a linha da cabeça"
          }
          onChange={(e, { name, value }) => setAssinale2(value)}
        />
        <Form.Radio
          label="Extensão total dos cotovelos e passa a linha da cabeça"
          value="Extensão total dos cotovelos e passa a linha da cabeça"
          checked={
            assinale2 ===
            "Extensão total dos cotovelos e passa a linha da cabeça"
          }
          onChange={(e, { name, value }) => setAssinale2(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Tempo</label>
        <Form.Radio
          label="1 minuto"
          value="1 minuto"
          checked={tempo === "1 minuto"}
          onChange={(e, { name, value }) => setTempo(value)}
        />
        <Form.Radio
          label="40 segundos"
          value="40 segundos"
          checked={tempo === "40 segundos"}
          onChange={(e, { name, value }) => setTempo(value)}
        />
        <Form.Radio
          label="30 segundos"
          value="30 segundos"
          checked={tempo === "30 segundos"}
          onChange={(e, { name, value }) => setTempo(value)}
        />
        <Form.Radio
          label="20 segundos"
          value="20 segundos"
          checked={tempo === "20 segundos"}
          onChange={(e, { name, value }) => setTempo(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Ângulo</label>
        <Form.Radio
          label="30°"
          value="30°"
          checked={angulo === "30°"}
          onChange={(e, { name, value }) => setAngulo(value)}
        />
        <Form.Radio
          label="45°"
          value="45°"
          checked={angulo === "45°"}
          onChange={(e, { name, value }) => setAngulo(value)}
        />
        <Form.Radio
          label="60°"
          value="60°"
          checked={angulo === "60°"}
          onChange={(e, { name, value }) => setAngulo(value)}
        />
        <Form.Radio
          label="90°"
          value="90°"
          checked={angulo === "90°"}
          onChange={(e, { name, value }) => setAngulo(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Rotação para esquerda</label>
        <Form.Radio
          label="Cruza cintura pélvica"
          value="Cruza cintura pélvica"
          checked={rotacaoEsquerda === "Cruza cintura pélvica"}
          onChange={(e, { name, value }) => setRotacaoEsquerda(value)}
        />
        <Form.Radio
          label="Limitada"
          value="Limitada"
          checked={rotacaoEsquerda === "Limitada"}
          onChange={(e, { name, value }) => setRotacaoEsquerda(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Rotação para direita</label>
        <Form.Radio
          label="Cruza cintura pélvica"
          value="Cruza cintura pélvica"
          checked={rotacaoDireita === "Cruza cintura pélvica"}
          onChange={(e, { name, value }) => setRotacaoDireita(value)}
        />
        <Form.Radio
          label="Limitada"
          value="Limitada"
          checked={rotacaoDireita === "Limitada"}
          onChange={(e, { name, value }) => setRotacaoDireita(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Assinale</label>
        <Form.Radio
          label="Pouca Mobilidade"
          value="Pouca Mobilidade"
          checked={assinale3 === "Pouca Mobilidade"}
          onChange={(e, { name, value }) => setAssinale3(value)}
        />
        <Form.Radio
          label="Dores na lombar"
          value="Dores na lombar"
          checked={assinale3 === "Dores na lombar"}
          onChange={(e, { name, value }) => setAssinale3(value)}
        />
        <Form.Radio
          label="Não consegue"
          value="Não consegue"
          checked={assinale3 === "Não consegue"}
          onChange={(e, { name, value }) => setAssinale3(value)}
        />
        <Form.Radio
          label="Consegue"
          value="Consegue"
          checked={assinale3 === "Consegue"}
          onChange={(e, { name, value }) => setAssinale3(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Postura</label>
        <Form.Radio
          label="Retificação lombar"
          value="Retificação lombar"
          checked={postura === "Retificação lombar"}
          onChange={(e, { name, value }) => setPostura(value)}
        />
        <Form.Radio
          label="Hiperlordose"
          value="Hiperlordose"
          checked={postura === "Hiperlordose"}
          onChange={(e, { name, value }) => setPostura(value)}
        />
        <Form.Radio
          label="Hipersifose dorsal"
          value="Hipersifose dorsal"
          checked={postura === "Hipersifose dorsal"}
          onChange={(e, { name, value }) => setPostura(value)}
        />
        <Form.Radio
          label="Anteriorização da cabeça"
          value="Anteriorização da cabeça"
          checked={postura === "Anteriorização da cabeça"}
          onChange={(e, { name, value }) => setPostura(value)}
        />
        <Form.Radio
          label="Postura correta"
          value="Postura correta"
          checked={postura === "Postura correta"}
          onChange={(e, { name, value }) => setPostura(value)}
        />
      </Form.Group>
      <Form.Group>
        <label>Posição dos joelhos</label>
        <Form.Radio
          label="Normal"
          value="Normal"
          checked={posicaoJoelhos === "Normal"}
          onChange={(e, { name, value }) => setPosicaoJoelhos(value)}
        />
        <Form.Radio
          label="Valgo"
          value="Valgo"
          checked={posicaoJoelhos === "Valgo"}
          onChange={(e, { name, value }) => setPosicaoJoelhos(value)}
        />
        <Form.Radio
          label="Varo"
          value="Varo"
          checked={posicaoJoelhos === "Varo"}
          onChange={(e, { name, value }) => setPosicaoJoelhos(value)}
        />
      </Form.Group>
    </>
  );
}
