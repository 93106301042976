import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import * as inspecaoLabels from "../pages/Acupuntura/Forms/labels/inspecaoLabels";
import * as compleicaoLabels from "../pages/Acupuntura/Forms/labels/compleicaoLabels";
import * as corDaPeleLabels from "../pages/Acupuntura/Forms/labels/corDaPeleLabels";
import * as auscultacaoLabels from "../pages/Acupuntura/Forms/labels/auscultacaoLabels";
import * as algiasLabels from "../pages/Acupuntura/Forms/labels/algiasLabels";
import * as interrogatorioLabels from "../pages/Acupuntura/Forms/labels/interrogatorioLabels";
import * as orgaoDosSentidosLabels from "../pages/Acupuntura/Forms/labels/orgaoDosSentidosLabels";
import Lingua1 from "../assets/images/Lingua1.png";
import Lingua2 from "../assets/images/Lingua2.png";
import Lingua3 from "../assets/images/Lingua3.png";
import Lingua4 from "../assets/images/Lingua4.png";
import Lingua5 from "../assets/images/Lingua5.png";
import Lingua6 from "../assets/images/Lingua6.png";
import AguaElemento from "../assets/images/AguaElemento.png";
import FogoElemento from "../assets/images/FogoElemento.png";
import MadeiraElemento from "../assets/images/MadeiraElemento.png";
import MetalElemento from "../assets/images/MetalElemento.png";
import TerraElemento from "../assets/images/TerraElemento.png";
import curvedArrow from "../assets/images/curvedArrow.png";
import Arrow from "../assets/images/Arrow.png";
import logoChen from "../assets/images/BackgroundHome.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  section: {
    marginRight: 10,
    padding: "5px 10px",
    flexDirection: "row",
  },
  textContent: {
    fontSize: 10,
  },
  viewContent: {
    flexDirection: "row",
    marginRight: 10,
    marginLeft: 10,
  },
  header: {
    alignItems: "center",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
  },
  body: {
    marginRight: 10,
    marginLeft: 10,
    padding: 10,
  },
  categoryHeader: {
    padding: "5px 10px",
    backgroundColor: "#e0e0e0",
    margin: "10px 0px",
  },
  fieldHeader: {
    padding: "10px 0px 5px 0px",
    fontSize: 15,
  },
  fieldContent: {
    fontSize: 10,
    color: "#9c9c9c",
  },
  logo: {
    margin: "0px 0px 10px 20px",
    width: 50,
    height: 50,
  },
  titleDocument: {
    padding: "10px 0px",
    textAlign: "center",
    textDecoration: "underline",
  },
  pontosAcupuntura: {
    flexDirection: "row",
    alignItems: "center",
  },
  acupunturaHeader: {
    padding: "5px 10px",
    fontSize: 10,
  },
});

// Create Document Component
export function AcupunturaPDF({ data }) {
  function checkAba(aba) {
    for (var prop in aba) {
      if (prop === "id") continue;
      if (
        aba[prop] !== "" &&
        aba[prop] !== "[]" &&
        aba[prop] !== "0" &&
        aba[prop] !== "100px" &&
        aba[prop] !== 0
      )
        return true;
    }
    return false;
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image src={logoChen} style={styles.logo} />
          <View>
            <View style={styles.section}>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Nome do Paciente: </Text>
                <Text style={styles.textContent}>
                  {data.Cliente.primeiroNome} {data.Cliente.sobrenome}
                </Text>
              </View>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Email: </Text>
                <Text style={styles.textContent}>{data.Cliente.email}</Text>
              </View>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Sexo: </Text>
                <Text style={styles.textContent}>{data.Cliente.sexo}</Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Data de Nascimento: </Text>
                <Text style={styles.textContent}>{data.Cliente.dataNasc}</Text>
              </View>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Telefone: </Text>
                <Text style={styles.textContent}>{data.Cliente.telefone}</Text>
              </View>
              <View style={styles.viewContent}>
                <Text style={styles.textContent}>Celular: </Text>
                <Text style={styles.textContent}>{data.Cliente.celular}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.body}>
          <Text style={styles.titleDocument}>ACUPUNTURA</Text>
          {checkAba(data.Sessao) ? (
            <>
              <Text style={styles.categoryHeader}>Sessão</Text>
              {data.Sessao.queixaPrincipal !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Queixa Principal</Text>
                  <Text style={styles.fieldContent}>
                    {data.Sessao.queixaPrincipal}
                  </Text>
                </>
              ) : null}
              {data.Sessao.queixaSecundaria !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Queixa Secundária</Text>
                  <Text style={styles.fieldContent}>
                    {data.Sessao.queixaSecundaria}
                  </Text>
                </>
              ) : null}
              {data.Sessao.observacao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Sessao.observacao}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Inspecao) ? (
            <>
              <Text style={styles.categoryHeader}>Inspeção</Text>
              {data.Inspecao.lingua !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Língua</Text>
                  {JSON.parse(data.Inspecao.lingua).map((item) => {
                    return inspecaoLabels.label.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Inspecao.hma !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    História da Moléstia Atual (HMA)
                  </Text>
                  <Text style={styles.fieldContent}>{data.Inspecao.hma}</Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Compleicao) ? (
            <>
              <Text style={styles.categoryHeader}>Compleição</Text>
              {data.Compleicao.geral !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Geral</Text>
                  {JSON.parse(data.Compleicao.geral).map((item) => {
                    return compleicaoLabels.label.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Compleicao.aspectoGeral !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Aspecto geral do corpo (forma, postura, locomoção, astenia)
                  </Text>
                  <Text style={styles.fieldContent}>
                    {data.Compleicao.aspectoGeral}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.CorPele) ? (
            <>
              <Text style={styles.categoryHeader}>Cor da Pele</Text>
              {data.CorPele.corPele !== "[]" ? (
                <>
                  {JSON.parse(data.CorPele.corPele).map((item) => {
                    return corDaPeleLabels.label.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.CorPele.expressaoRosto !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Expressão do Rosto</Text>
                  <Text style={styles.fieldContent}>
                    {data.CorPele.expressaoRosto}
                  </Text>
                </>
              ) : null}
              {data.CorPele.cabecaCabelos !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Cabeça e cabelos</Text>
                  <Text style={styles.fieldContent}>
                    {data.CorPele.cabecaCabelos}
                  </Text>
                </>
              ) : null}
              {data.CorPele.olhos !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Olhos (brilho, aspecto, coloração, movimentos, edema de
                    pálpebras)
                  </Text>
                  <Text style={styles.fieldContent}>{data.CorPele.olhos}</Text>
                </>
              ) : null}
              {data.CorPele.nariz !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Nariz (Deformidades, secreções)
                  </Text>
                  <Text style={styles.fieldContent}>{data.CorPele.nariz}</Text>
                </>
              ) : null}
              {data.CorPele.orelhas !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Orelhas (Coloração, brilho,aspecto interior)
                  </Text>
                  <Text style={styles.fieldContent}>
                    {data.CorPele.orelhas}
                  </Text>
                </>
              ) : null}
              {data.CorPele.labios !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Lábios, dentes, gengiva, garganta (cor, umidade, forma,
                    ulceração, morfologia)
                  </Text>
                  <Text style={styles.fieldContent}>{data.CorPele.labios}</Text>
                </>
              ) : null}
              {data.CorPele.pele !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Pele (alterações da cor, textura, cicatrizes, alterações
                    morfológicas)
                  </Text>
                  <Text style={styles.fieldContent}>{data.CorPele.pele}</Text>
                </>
              ) : null}
              {data.CorPele.observacao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.CorPele.observacao}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Auscultacao) ? (
            <>
              <Text style={styles.categoryHeader}>Auscultação</Text>
              {data.Auscultacao.fala !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Fala</Text>
                  {JSON.parse(data.Auscultacao.fala).map((item) => {
                    return auscultacaoLabels.labelFala.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Auscultacao.observacaoFala !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Auscultacao.observacaoFala}
                  </Text>
                </>
              ) : null}
              {data.Auscultacao.respiracao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Respiração</Text>
                  {JSON.parse(data.Auscultacao.respiracao).map((item) => {
                    return auscultacaoLabels.labelFala.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Auscultacao.observacaoRespiracao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Auscultacao.observacaoRespiracao}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Interrogatorio) ? (
            <>
              <Text style={styles.categoryHeader}>Interrogatório</Text>
              {data.Interrogatorio.transpiracao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Transpiração</Text>
                  {JSON.parse(data.Interrogatorio.transpiracao).map((item) => {
                    return interrogatorioLabels.labels.transpiracao.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obTranspiracao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obTranspiracao}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.sono !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Sono</Text>
                  {JSON.parse(data.Interrogatorio.sono).map((item) => {
                    return interrogatorioLabels.labels.sono.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obSono !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obSono}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.emocoes !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Emoções</Text>
                  {JSON.parse(data.Interrogatorio.emocoes).map((item) => {
                    return interrogatorioLabels.labels.emocoes.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obEmocoes !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obEmocoes}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.alimentacao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Alimentação</Text>
                  {JSON.parse(data.Interrogatorio.alimentacao).map((item) => {
                    return interrogatorioLabels.labels.alimentacao.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obAlimentacao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obAlimentacao}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.sabores !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Sabores</Text>
                  {JSON.parse(data.Interrogatorio.sabores).map((item) => {
                    return interrogatorioLabels.labels.sabores.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obSabores !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obSabores}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.disfuncoes !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Disfunções Gastrointestinais
                  </Text>
                  {JSON.parse(data.Interrogatorio.disfuncoes).map((item) => {
                    return interrogatorioLabels.labels.disfuncoes.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obDisfuncoes !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obDisfuncoes}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.excrecoes !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Excreções</Text>
                  {JSON.parse(data.Interrogatorio.excrecoes).map((item) => {
                    return interrogatorioLabels.labels.excrecoes.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obExcrecoes !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obExcrecoes}
                  </Text>
                </>
              ) : null}
              {data.Interrogatorio.menstruacao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Menstruação</Text>
                  {JSON.parse(data.Interrogatorio.menstruacao).map((item) => {
                    return interrogatorioLabels.labels.menstruacao.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Interrogatorio.obMenstruacao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Interrogatorio.obMenstruacao}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.OrgaoSentido) ? (
            <>
              <Text style={styles.categoryHeader}>Orgão dos Sentidos</Text>
              {data.OrgaoSentido.olhosVisao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Olhos e Visão</Text>
                  {JSON.parse(data.OrgaoSentido.olhosVisao).map((item) => {
                    return orgaoDosSentidosLabels.labels.olhosVisao.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.OrgaoSentido.ouvidosAudicao !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Ouvidos e Audição</Text>
                  {JSON.parse(data.OrgaoSentido.ouvidosAudicao).map((item) => {
                    return orgaoDosSentidosLabels.labels.ouvidosAudicao.map(
                      (l) => {
                        return l.map((it) => {
                          if (it.value === item)
                            return (
                              <Text style={styles.fieldContent}>
                                • {it.text}
                              </Text>
                            );
                          else return null;
                        });
                      }
                    );
                  })}
                </>
              ) : null}
              {data.OrgaoSentido.narizOlfato !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Nariz e Olfato</Text>
                  {JSON.parse(data.OrgaoSentido.narizOlfato).map((item) => {
                    return orgaoDosSentidosLabels.labels.narizOlfato.map(
                      (l) => {
                        return l.map((it) => {
                          if (it.value === item)
                            return (
                              <Text style={styles.fieldContent}>
                                • {it.text}
                              </Text>
                            );
                          else return null;
                        });
                      }
                    );
                  })}
                </>
              ) : null}
              {data.OrgaoSentido.tato !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Tato</Text>
                  {JSON.parse(data.OrgaoSentido.tato).map((item) => {
                    return orgaoDosSentidosLabels.labels.tato.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.OrgaoSentido.bocaGosto !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Boca e Gosto</Text>
                  {JSON.parse(data.OrgaoSentido.bocaGosto).map((item) => {
                    return orgaoDosSentidosLabels.labels.bocaGosto.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.OrgaoSentido.observacao !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.OrgaoSentido.observacao}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Algia) ? (
            <>
              <Text style={styles.categoryHeader}>Algias</Text>
              {data.Algia.coluna !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Coluna</Text>
                  {JSON.parse(data.Algia.coluna).map((item) => {
                    return algiasLabels.labels.coluna.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.obColuna !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>{data.Algia.obColuna}</Text>
                </>
              ) : null}
              {data.Algia.doresMusculares !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Dores Musculares</Text>
                  {JSON.parse(data.Algia.doresMusculares).map((item) => {
                    return algiasLabels.labels.doresMusculares.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.doresArticulares !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Dores Articulares</Text>
                  {JSON.parse(data.Algia.doresArticulares).map((item) => {
                    return algiasLabels.labels.doresArticulares.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.obdoresArticulares !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Algia.obdoresArticulares}
                  </Text>
                </>
              ) : null}
              {data.Algia.abdomen !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Abdômen</Text>
                  {JSON.parse(data.Algia.abdomen).map((item) => {
                    return algiasLabels.labels.abdomen.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.torax !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Algia.obAbdomen}
                  </Text>
                </>
              ) : null}
              {data.Algia.torax !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Tórax</Text>
                  {JSON.parse(data.Algia.torax).map((item) => {
                    return algiasLabels.labels.torax.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.obTorax !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>{data.Algia.obTorax}</Text>
                </>
              ) : null}
              {data.Algia.doresCabeca !== "[]" ? (
                <>
                  <Text style={styles.fieldHeader}>Dores de Cabeça</Text>
                  {JSON.parse(data.Algia.doresCabeca).map((item) => {
                    return algiasLabels.labels.doresCabeca.map((l) => {
                      return l.map((it) => {
                        if (it.value === item)
                          return (
                            <Text style={styles.fieldContent}>• {it.text}</Text>
                          );
                        else return null;
                      });
                    });
                  })}
                </>
              ) : null}
              {data.Algia.obDoresCabeca !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Observação</Text>
                  <Text style={styles.fieldContent}>
                    {data.Algia.obDoresCabeca}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.Palpacao) ? (
            <>
              <Text style={styles.categoryHeader}>Palpação</Text>
              {data.Palpacao.metalP !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Metal - P</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.metalP}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.terraBP !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Terra - BP</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.terraBP}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.fogoCS !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Fogo - CS</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.fogoCS}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.fogoC !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Fogo - C</Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.fogoC}</Text>
                </>
              ) : null}
              {data.Palpacao.madF !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Mad. - F</Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.madF}</Text>
                </>
              ) : null}
              {data.Palpacao.aguaR !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Água - R</Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.aguaR}</Text>
                </>
              ) : null}
              {data.Palpacao.metalIG !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Metal - IG</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.metalIG}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.terraE !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Terra - E</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.terraE}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.fogoTA !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Fogo - TA</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.fogoTA}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.fogoID !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Fogo - ID</Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.fogoID}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.madVB !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Mad. - VB</Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.madVB}</Text>
                </>
              ) : null}
              {data.Palpacao.aguaB !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>Agua - B</Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.aguaB}</Text>
                </>
              ) : null}
              {data.Palpacao.pele !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Pele (temperatura, umidade, edemas)
                  </Text>
                  <Text style={styles.fieldContent}>{data.Palpacao.pele}</Text>
                </>
              ) : null}
              {data.Palpacao.temperatura !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Temperatura de mãos e pés
                  </Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.temperatura}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.abdome !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Abdome (umidade de pele, dores à palpação, grau de firmeza,
                    edemas, massas)
                  </Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.abdome}
                  </Text>
                </>
              ) : null}
              {data.Palpacao.sensibilidade !== "" ? (
                <>
                  <Text style={styles.fieldHeader}>
                    Sensibilidade nos pontos e no trajeto dos meridianos
                  </Text>
                  <Text style={styles.fieldContent}>
                    {data.Palpacao.sensibilidade}
                  </Text>
                </>
              ) : null}
            </>
          ) : null}

          {checkAba(data.CincoElemento) ? (
            <>
              <Text style={styles.categoryHeader}>Cinco Elementos</Text>
              <View>
                <Image
                  style={{
                    position: "absolute",
                    top: "250px",
                    left: "60px",
                    height: Number(
                      data.CincoElemento.aguaSize.replace("px", "")
                    ),
                    width: Number(
                      data.CincoElemento.aguaSize.replace("px", "")
                    ),
                  }}
                  src={AguaElemento}
                />
                <Image
                  style={{
                    position: "absolute",
                    left: "150px",
                    height: Number(
                      data.CincoElemento.fogoSize.replace("px", "")
                    ),
                    width: Number(
                      data.CincoElemento.fogoSize.replace("px", "")
                    ),
                  }}
                  src={FogoElemento}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "80px",
                    height: Number(
                      data.CincoElemento.madeiraSize.replace("px", "")
                    ),
                    width: Number(
                      data.CincoElemento.madeiraSize.replace("px", "")
                    ),
                  }}
                  src={MadeiraElemento}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "250px",
                    left: "250px",
                    height: Number(
                      data.CincoElemento.metalSize.replace("px", "")
                    ),
                    width: Number(
                      data.CincoElemento.metalSize.replace("px", "")
                    ),
                  }}
                  src={MetalElemento}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "80px",
                    left: "300px",
                    height: Number(
                      data.CincoElemento.terraSize.replace("px", "")
                    ),
                    width: Number(
                      data.CincoElemento.terraSize.replace("px", "")
                    ),
                  }}
                  src={TerraElemento}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "125px",
                    left: "124px",
                    width: "150px",
                    transform:
                      "rotate(" + Number(data.CincoElemento.rotation1) + "deg)",
                  }}
                  src={Arrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "172px",
                    left: "169px",
                    width: "150px",
                    transform:
                      "rotate(" +
                      (Number(data.CincoElemento.rotation2) + 65) +
                      "deg)",
                  }}
                  src={Arrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "195px",
                    left: "149px",
                    width: "150px",
                    transform:
                      "rotate(" +
                      (Number(data.CincoElemento.rotation3) + 144) +
                      "deg)",
                  }}
                  src={Arrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "203px",
                    left: "108px",
                    width: "150px",
                    transform:
                      "rotate(" +
                      (Number(data.CincoElemento.rotation4) + -144) +
                      "deg)",
                  }}
                  src={Arrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "165px",
                    left: "90px",
                    width: "150px",
                    transform:
                      "rotate(" +
                      (Number(data.CincoElemento.rotation5) + -67) +
                      "deg)",
                  }}
                  src={Arrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "50px",
                    left: "70px",
                    height: "30px",
                    transform: "scaleX(-1) rotate(210deg)",
                  }}
                  src={curvedArrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "50px",
                    left: "250px",
                    height: "30px",
                    transform: "scaleX(-1) rotate(170deg)",
                  }}
                  src={curvedArrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "205px",
                    left: "300px",
                    height: "30px",
                    transform: "scaleX(-1) rotate(80deg)",
                  }}
                  src={curvedArrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "299px",
                    left: "170px",
                    height: "30px",
                    transform: "scaleX(-1) rotate(8deg)",
                  }}
                  src={curvedArrow}
                />
                <Image
                  style={{
                    position: "absolute",
                    top: "209px",
                    left: "20px",
                    height: "30px",
                    transform: "scaleX(-1) rotate(-57deg)",
                  }}
                  src={curvedArrow}
                />
              </View>
              <View style={{ marginTop: "350px" }}>
                {data.CincoElemento.observacao !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>Observação</Text>
                    <Text style={styles.fieldContent}>
                      {data.CincoElemento.observacao}
                    </Text>
                  </>
                ) : null}
              </View>
            </>
          ) : null}

          {data.pontos !== "[]" ? (
            <>
              <Text style={styles.categoryHeader}>Acupuntura</Text>
              {JSON.parse(data.pontos).map((item) => {
                return (
                  <View>
                    <View style={styles.pontosAcupuntura}>
                      <Text style={styles.acupunturaHeader}>Ponto:</Text>
                      <Text style={styles.fieldContent}>{item.ponto}</Text>
                      <Text style={styles.acupunturaHeader}>Ação:</Text>
                      <Text style={styles.fieldContent}>{item.acao}</Text>
                    </View>
                  </View>
                );
              })}
            </>
          ) : null}

          {checkAba(data.Lingua) ? (
            <>
              <Text style={styles.categoryHeader}>Língua</Text>
              <View>
                <Image
                  style={{
                    position: "absolute",
                    height: "294px",
                    top: "31px",
                    left: "212px",
                    opacity: data.Lingua.opacity1 ? 1 : 0.6,
                  }}
                  src={Lingua1}
                />
                <Image
                  style={{
                    position: "absolute",
                    height: "294px",
                    top: "30px",
                    left: "-3px",
                    opacity: data.Lingua.opacity2 ? 1 : 0.6,
                  }}
                  src={Lingua2}
                />
                <Image
                  style={{
                    position: "absolute",
                    height: "204px",
                    top: "75px",
                    left: "66px",
                    opacity: data.Lingua.opacity3 ? 1 : 0.6,
                  }}
                  src={Lingua3}
                />
                <Image
                  style={{
                    position: "absolute",
                    height: "100px",
                    top: "8px",
                    left: "8px",
                    opacity: data.Lingua.opacity4 ? 1 : 0.6,
                  }}
                  src={Lingua4}
                />
                <Image
                  style={{
                    position: "absolute",
                    height: "102px",
                    top: "230px",
                    left: "56px",
                    opacity: data.Lingua.opacity5 ? 1 : 0.6,
                  }}
                  src={Lingua5}
                />
                <Image
                  style={{
                    position: "absolute",
                    height: "96px",
                    top: "281px",
                    left: "63px",
                    opacity: data.Lingua.opacity6 ? 1 : 0.6,
                  }}
                  src={Lingua6}
                />
              </View>
              <View style={{ marginTop: "400px" }}>
                {data.Lingua.lingua1 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 1 - Rim/Bexiga
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua1}
                    </Text>
                  </>
                ) : null}
                {data.Lingua.lingua2 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 2 - Vesícula Biliar/Figado
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua2}
                    </Text>
                  </>
                ) : null}
                {data.Lingua.lingua3 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 3 - Figado/Vesícula Biliar
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua3}
                    </Text>
                  </>
                ) : null}
                {data.Lingua.lingua4 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 4 - Baço/Estômago
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua4}
                    </Text>
                  </>
                ) : null}
                {data.Lingua.lingua5 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 5 - Pulmão/Int. Grosso
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua5}
                    </Text>
                  </>
                ) : null}
                {data.Lingua.lingua6 !== "" ? (
                  <>
                    <Text style={styles.fieldHeader}>
                      Língua 6 - Coração/Int. Delgado
                    </Text>
                    <Text style={styles.fieldContent}>
                      {data.Lingua.lingua6}
                    </Text>
                  </>
                ) : null}
              </View>
            </>
          ) : null}
        </View>
      </Page>
    </Document>
  );
}
