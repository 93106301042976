import React, { useState, createRef, useEffect } from "react";
import {
  Menu,
  Message,
  Grid,
  Card,
  Image,
  Sticky,
  Ref,
  Rail,
  Button,
  Form,
  Header,
} from "semantic-ui-react";
import Timer from "react-compound-timer";
import "./main.css";
import { useSelector } from "react-redux";
import Sidebar from "../../assets/Sidebar";
import Sessao from "./Forms/Sessao";
import Inspecao from "./Forms/Inspecao";
import Compleicao from "./Forms/Compleicao";
import CorDaPele from "./Forms/CorDapele";
import Auscultacao from "./Forms/Auscultacao";
import Interrogatorio from "./Forms/Interrogatorio";
import OrgaoDosSentidos from "./Forms/OrgaoDosSentidos";
import Algias from "./Forms/Algias";
import Palpacao from "./Forms/Palpacao";
import AcupunturaSessao from "./Forms/AcupunturaSessao";
import CincoElementos from "./Forms/CincoElementos";
import Lingua from "./Forms/Lingua";
import * as moment from "moment";
import axios from "axios";
import Modal from "./modal";

export default function Acupuntura() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const { cliente } = useSelector((state) => state.cliente);
  const [activeItem, setActiveItem] = useState("Sessão");
  const contextRef = createRef();
  const [loading, setLoading] = useState(false);
  const [duracao, setDuracao] = useState("");
  const [isEdit, setIsEdit] = useState(acupuntura.isEdit);
  const [msg, setMsg] = useState("");
  const newTreatment = acupuntura.newTreatment;
  const initialTime = acupuntura.duracao ? acupuntura.duracao : 0;

  function checkAccess() {
    const acessos = localStorage.getItem("@wd_acessos");
    if (acessos[11] === "1") {
      return true;
    } else {
      return false;
    }
  }

  async function sendForm() {
    setLoading(true);
    const now = moment().format();
    const c = {
      cliente: cliente.id,
      duracao: duracao,
      date: now,
      acupuntura: acupuntura,
    };
    const res = await axios.post(
      process.env.REACT_APP_API_ENDPOINT + `Acupuntura`,
      c
    );
    setLoading(false);
    return res;
  }

  console.log(newTreatment);

  if (checkAccess()) {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="divContainer">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Message
                  icon="inbox"
                  header="Acupuntura"
                  //content="Get the best news in your e-mail every day."
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Ref innerRef={contextRef}>
                  <Rail internal position="left" attached className="railLeft">
                    <Sticky context={contextRef} offset={72}>
                      <Card>
                        <Card.Content>
                          <Image
                            floated="right"
                            size="mini"
                            src="https://react.semantic-ui.com/images/avatar/large/matthew.png"
                          />
                          <Card.Header>{cliente.nome}</Card.Header>
                          <Card.Meta>{cliente.email}</Card.Meta>
                          <Card.Description>
                            <strong>Tempo:</strong>&nbsp;
                            <Timer
                              initialTime={initialTime}
                              startImmediately={!isEdit}
                            >
                              {({ getTime }) => (
                                <React.Fragment>
                                  <Timer.Minutes /> : &nbsp;
                                  <Timer.Seconds />
                                  {setDuracao(getTime())}
                                </React.Fragment>
                              )}
                            </Timer>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                      {newTreatment ? (
                        <Menu pointing secondary vertical>
                          <Menu.Item
                            name="Sessão"
                            active={activeItem === "Sessão"}
                            onClick={() => setActiveItem("Sessão")}
                          />
                          <Menu.Item
                            name="Inspeção"
                            active={activeItem === "Inspeção"}
                            onClick={() => setActiveItem("Inspeção")}
                          />
                          <Menu.Item
                            name="Compleição"
                            active={activeItem === "Compleição"}
                            onClick={() => setActiveItem("Compleição")}
                          />
                          <Menu.Item
                            name="Cor da Pele"
                            active={activeItem === "Cor da Pele"}
                            onClick={() => setActiveItem("Cor da Pele")}
                          />
                          <Menu.Item
                            name="Auscultação"
                            active={activeItem === "Auscultação"}
                            onClick={() => setActiveItem("Auscultação")}
                          />
                          <Menu.Item
                            name="Interrogatório"
                            active={activeItem === "Interrogatório"}
                            onClick={() => setActiveItem("Interrogatório")}
                          />
                          <Menu.Item
                            name="Orgão dos Sentidos"
                            active={activeItem === "Orgão dos Sentidos"}
                            onClick={() => setActiveItem("Orgão dos Sentidos")}
                          />
                          <Menu.Item
                            name="Algias"
                            active={activeItem === "Algias"}
                            onClick={() => setActiveItem("Algias")}
                          />
                          <Menu.Item
                            name="Palpação"
                            active={activeItem === "Palpação"}
                            onClick={() => setActiveItem("Palpação")}
                          />
                          <Menu.Item
                            name="Cinco Elementos"
                            active={activeItem === "Cinco Elementos"}
                            onClick={() => setActiveItem("Cinco Elementos")}
                          />
                          <Menu.Item
                            name="Acupuntura"
                            active={activeItem === "Acupuntura"}
                            onClick={() => setActiveItem("Acupuntura")}
                          />
                          <Menu.Item
                            name="Língua"
                            active={activeItem === "Língua"}
                            onClick={() => setActiveItem("Língua")}
                          />
                        </Menu>
                      ) : (
                        <Menu pointing secondary vertical>
                          <Menu.Item
                            name="Sessão"
                            active={activeItem === "Sessão"}
                            onClick={() => setActiveItem("Sessão")}
                          />
                        </Menu>
                      )}
                      {!isEdit ? <Modal sendForm={sendForm} /> : null}
                    </Sticky>
                  </Rail>
                </Ref>
              </Grid.Column>
              <Grid.Column width={13} style={{ paddingLeft: "5rem" }}>
                <Header as="h2">{activeItem}</Header>
                <Form>
                  {(() => {
                    switch (activeItem) {
                      case "Sessão":
                        return <Sessao />;
                      case "Inspeção":
                        return <Inspecao />;
                      case "Compleição":
                        return <Compleicao />;
                      case "Cor da Pele":
                        return <CorDaPele />;
                      case "Auscultação":
                        return <Auscultacao />;
                      case "Interrogatório":
                        return <Interrogatorio />;
                      case "Orgão dos Sentidos":
                        return <OrgaoDosSentidos />;
                      case "Algias":
                        return <Algias />;
                      case "Palpação":
                        return <Palpacao />;
                      case "Acupuntura":
                        return <AcupunturaSessao />;
                      case "Cinco Elementos":
                        return <CincoElementos />;
                      case "Língua":
                        return <Lingua />;
                      default:
                        return null;
                    }
                  })()}
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Sidebar />
        <div className="divContainer">
          <p>Você não tem acesso à esse conteúdo</p>
        </div>
      </>
    );
  }
}
