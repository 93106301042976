export const labels = {
  olhosVisao: [
    [
      {
        key: 1,
        text: "Fraca (estagnação de QI do F, def. de Yin QI na B e R)",
        value: 1,
      },
      {
        key: 2,
        text: "Vermelhidão (fogo, ascensão do Yang QI do F)",
        value: 2,
      },
      { key: 3, text: "Lacrimejamento (QI perverso vento)", value: 3 },
    ],
    [
      { key: 4, text: "Turva (QI perverso vento, def. Xue do F)", value: 4 },
      { key: 5, text: "Secura (vazio de Yin QI do F)", value: 5 },
    ],
  ],
  ouvidosAudicao: [
    [
      {
        key: 1,
        text: "Zumbido (def. Yin QI na B e R, vento)",
        value: 1,
      },
      {
        key: 2,
        text: "Fraca (def. Yin QI da B e R)",
        value: 2,
      },
      { key: 3, text: "Surdez súbita (fogo no F)", value: 3 },
    ],
    [
      { key: 4, text: "Prurido (coceira)", value: 4 },
      {
        key: 5,
        text: "Surdez (def. sangue no C, def. Yin QI nas B e R)",
        value: 5,
      },
    ],
  ],
  narizOlfato: [
    [
      {
        key: 1,
        text: "Fraco (def. Yin QI dos P, QI perverso vento/frio)",
        value: 1,
      },
      {
        key: 2,
        text: "Coriza/mucosidade (QI perverso calor no P)",
        value: 2,
      },
      { key: 3, text: "Obstrução (QI perverso vento no P)", value: 3 },
    ],
    [
      { key: 4, text: "Anosmia (debilidade de IG)", value: 4 },
      {
        key: 5,
        text: "Prurido (QI perverso vento no P)",
        value: 5,
      },
    ],
  ],
  tato: [
    [
      {
        key: 1,
        text: "Fraco (def. QI do C)",
        value: 1,
      },
    ],
  ],
  bocaGosto: [
    [
      {
        key: 1,
        text: "Lábios azulados (estase de Xue do C)",
        value: 1,
      },
      {
        key: 2,
        text: "Lábios brancos (def. de Yin QI e Xue do C)",
        value: 2,
      },
      { key: 3, text: "Secura (Xie em P e Wei QI)", value: 3 },
      {
        key: 4,
        text: "Boca amarga (QI perverso calor/umidade em F e VB)",
        value: 4,
      },
    ],
    [
      {
        key: 5,
        text: "Lábios pálidos (QI perverso frio/umidade em BP)",
        value: 5,
      },
      {
        key: 6,
        text: "Salivação (QI perverso umidade em BP)",
        value: 6,
      },
      {
        key: 7,
        text: "Garganta seca (subida do Fogo do F)",
        value: 7,
      },
      {
        key: 8,
        text: "Sangramentos (def. de QI do BP)",
        value: 8,
      },
    ],
  ],
};
