const initialState = {
  duracao: "",
  isEdit: false,
  sessao: {
    queixaPrincipal: "",
    queixaSecundaria: "",
    observacao: "",
  },
  inspecao: {
    lingua: [],
    hma: "",
  },
  compleicao: {
    geral: [],
    aspectoGeral: "",
  },
  corPele: {
    corPele: [],
    expressaoRosto: "",
    cabecaCabelos: "",
    olhos: "",
    nariz: "",
    orelhas: "",
    labios: "",
    pele: "",
    observacao: "",
  },
  auscultacao: {
    fala: [],
    observacaoFala: "",
    respiracao: [],
    observacaoRespiracao: "",
  },
  interrogatorio: {
    transpiracao: [],
    obTranspiracao: "",
    observacaoFala: "",
    sono: [],
    obSono: "",
    emocoes: [],
    obEmocoes: "",
    alimentacao: [],
    obAlimentacao: "",
    sabores: [],
    obSabores: "",
    sede: [],
    obSede: "",
    disfuncoes: [],
    obDisfuncoes: "",
    excrecoes: [],
    obExcrecoes: "",
    menstruacao: [],
    obMenstruacao: "",
  },
  orgaoSentidos: {
    olhosVisao: [],
    ouvidosAudicao: [],
    narizOlfato: [],
    tato: [],
    bocaGosto: [],
    observacao: "",
  },
  algias: {
    coluna: [],
    obColuna: "",
    doresMusculares: [],
    doresArticulares: [],
    obdoresArticulares: "",
    abdomen: [],
    obAbdomen: "",
    torax: [],
    obTorax: "",
    doresCabeca: [],
    obDoresCabeca: "",
  },
  palpacao: {
    metalP: "",
    fogoC: "",
    terraBP: "",
    madF: "",
    fogoCS: "",
    aguaR: "",
    metalIG: "",
    fogoID: "",
    terraE: "",
    madVB: "",
    fogoTA: "",
    aguaB: "",
    pele: "",
    temperatura: "",
    abdome: "",
    sensibilidade: "",
  },
  cincoElementos: {
    aguaSize: "100px",
    fogoSize: "100px",
    madeiraSize: "100px",
    metalSize: "100px",
    terraSize: "100px",
    rotation1: 0,
    rotation2: 0,
    rotation3: 0,
    rotation4: 0,
    rotation5: 0,
    observacao: "",
  },
  acupuntura: {
    pontos: [],
  },
  lingua: {
    opacity1: false,
    opacity2: false,
    opacity3: false,
    opacity4: false,
    opacity5: false,
    opacity6: false,
    lingua1: "",
    lingua2: "",
    lingua3: "",
    lingua4: "",
    lingua5: "",
    lingua6: "",
  },
};

const setSessao = (sessaoObj) => {
  return {
    type: "SET_SESSAO",
    payload: sessaoObj,
  };
};

const setInspecao = (inspecaoObj) => {
  return {
    type: "SET_INSPECAO",
    payload: inspecaoObj,
  };
};

const setCompleicao = (compleicaoObj) => {
  return {
    type: "SET_COMPLEICAO",
    payload: compleicaoObj,
  };
};

const setCorPele = (corPeleObj) => {
  return {
    type: "SET_CORPELE",
    payload: corPeleObj,
  };
};

const setAuscultacao = (auscultacaoObj) => {
  return {
    type: "SET_AUSCULTACAO",
    payload: auscultacaoObj,
  };
};

const setInterrogatorio = (interrogatorioObj) => {
  return {
    type: "SET_INTERROGATORIO",
    payload: interrogatorioObj,
  };
};

const setOrgaoSentidos = (orgaoSentidosObj) => {
  return {
    type: "SET_ORGAOSENTIDOS",
    payload: orgaoSentidosObj,
  };
};

const setAlgias = (algiasObj) => {
  return {
    type: "SET_ALGIAS",
    payload: algiasObj,
  };
};

const setPalpacao = (palpacaoObj) => {
  return {
    type: "SET_PALPACAO",
    payload: palpacaoObj,
  };
};

const setCincoElementos = (cincoElementosObj) => {
  return {
    type: "SET_CINCOELEMENTOS",
    payload: cincoElementosObj,
  };
};

const setAcupuntura = (acupunturaObj) => {
  return {
    type: "SET_ACUPUNTURA",
    payload: acupunturaObj,
  };
};

const setLingua = (linguaObj) => {
  return {
    type: "SET_LINGUA",
    payload: linguaObj,
  };
};

const clearStateAcupuntura = () => {
  return {
    type: "CLEAR_STATE_ACUPUNTURA",
    payload: initialState,
  };
};

const setAcupunturaForm = (acupunturaObj) => {
  return {
    type: "SET_ACUPUNTURA_FORM",
    payload: acupunturaObj,
  };
};

export default {
  setSessao,
  setInspecao,
  setCompleicao,
  setCorPele,
  setAuscultacao,
  setInterrogatorio,
  setOrgaoSentidos,
  setAlgias,
  setPalpacao,
  setCincoElementos,
  setAcupuntura,
  setLingua,
  clearStateAcupuntura,
  setAcupunturaForm,
};
