const initialState = {
  cliente: {
    id: 0,
    nome: "",
    email: "",
    image: "",
  },
};

const setCliente = (clienteObj) => {
  return {
    type: "SET_CLIENTE",
    payload: clienteObj,
  };
};

const clearState = () => {
  return {
    type: "CLEAR_STATE",
    payload: initialState,
  };
};

const setNewTreatment = (newTreatmentObj) => {
  return {
    type: "SET_NOVO_TRATAMENTO",
    payload: newTreatmentObj,
  };
};

export default {
  setNewTreatment,
  setCliente,
  clearState,
};
