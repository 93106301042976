import { combineReducers } from "redux";
import avEnergetica from "./avEnergetica";
import acupuntura from "./acupuntura";
import cliente from "./cliente";

export const Reducers = combineReducers({
  avEnergetica,
  acupuntura,
  cliente,
});
