import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "./redux/actions";
import { Element, SetasCurvas, SetasRetas } from "../styles";
import { Form, TextArea } from "semantic-ui-react";
import AguaElemento from "../../../assets/images/AguaElemento.png";
import FogoElemento from "../../../assets/images/FogoElemento.png";
import MadeiraElemento from "../../../assets/images/MadeiraElemento.png";
import MetalElemento from "../../../assets/images/MetalElemento.png";
import TerraElemento from "../../../assets/images/TerraElemento.png";
import curvedArrow from "../../../assets/images/curvedArrow.png";
import Arrow from "../../../assets/images/Arrow.png";

export default function CincoElementos() {
  const acupuntura = useSelector((state) => state.acupuntura);
  const dispatch = useDispatch();
  const [aguaSize, setAguaSize] = useState(acupuntura.cincoElementos.aguaSize);
  const [fogoSize, setFogoSize] = useState(acupuntura.cincoElementos.fogoSize);
  const [madeiraSize, setMadeiraSize] = useState(
    acupuntura.cincoElementos.madeiraSize
  );
  const [metalSize, setMetalSize] = useState(
    acupuntura.cincoElementos.metalSize
  );
  const [terraSize, setTerraSize] = useState(
    acupuntura.cincoElementos.terraSize
  );
  const [rotation1, setRotation1] = useState(
    acupuntura.cincoElementos.rotation1
  );
  const [rotation2, setRotation2] = useState(
    acupuntura.cincoElementos.rotation2
  );
  const [rotation3, setRotation3] = useState(
    acupuntura.cincoElementos.rotation3
  );
  const [rotation4, setRotation4] = useState(
    acupuntura.cincoElementos.rotation4
  );
  const [rotation5, setRotation5] = useState(
    acupuntura.cincoElementos.rotation5
  );
  const [observacao, setObservacao] = useState(
    acupuntura.cincoElementos.observacao
  );

  useEffect(() => {
    dispatch(
      actions.setCincoElementos({
        aguaSize: aguaSize,
        fogoSize: fogoSize,
        madeiraSize: madeiraSize,
        metalSize: metalSize,
        terraSize: terraSize,
        rotation1: rotation1,
        rotation2: rotation2,
        rotation3: rotation3,
        rotation4: rotation4,
        rotation5: rotation5,
        observacao: observacao,
      })
    );
  }, [
    dispatch,
    aguaSize,
    fogoSize,
    madeiraSize,
    metalSize,
    terraSize,
    rotation1,
    rotation2,
    rotation3,
    rotation4,
    rotation5,
    observacao,
  ]);

  function increaseSize(element) {
    switch (element) {
      case "agua":
        if (aguaSize === "110px") {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        } else {
          setAguaSize("110px");
          setFogoSize("70px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        }
        break;
      case "madeira":
        if (madeiraSize === "110px") {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        } else {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("110px");
          setMetalSize("100px");
          setTerraSize("70px");
        }
        break;
      case "fogo":
        if (fogoSize === "110px") {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        } else {
          setAguaSize("100px");
          setFogoSize("110px");
          setMadeiraSize("100px");
          setMetalSize("70px");
          setTerraSize("100px");
        }
        break;
      case "terra":
        if (terraSize === "110px") {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        } else {
          setAguaSize("70px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("110px");
        }
        break;
      case "metal":
        if (metalSize === "110px") {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("100px");
          setMetalSize("100px");
          setTerraSize("100px");
        } else {
          setAguaSize("100px");
          setFogoSize("100px");
          setMadeiraSize("70px");
          setMetalSize("110px");
          setTerraSize("100px");
        }
        break;
      default:
        break;
    }
  }

  function rotateArrow(arrow) {
    switch (arrow) {
      case 1:
        if (rotation1 === 180) setRotation1(0);
        else setRotation1(180);
        break;
      case 2:
        if (rotation2 === 180) setRotation2(0);
        else setRotation2(180);
        break;
      case 3:
        if (rotation3 === 180) setRotation3(0);
        else setRotation3(180);
        break;
      case 4:
        if (rotation4 === 180) setRotation4(0);
        else setRotation4(180);
        break;
      case 5:
        if (rotation5 === 180) setRotation5(0);
        else setRotation5(180);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Element
        onClick={() => increaseSize("agua")}
        size={aguaSize}
        src={AguaElemento}
        top="250px"
        left="60px"
      />
      <Element
        onClick={() => increaseSize("fogo")}
        size={fogoSize}
        src={FogoElemento}
        left="150px"
      />
      <Element
        onClick={() => increaseSize("madeira")}
        size={madeiraSize}
        src={MadeiraElemento}
        top="80px"
      />
      <Element
        onClick={() => increaseSize("metal")}
        size={metalSize}
        src={MetalElemento}
        top="250px"
        left="250px"
      />
      <Element
        onClick={() => increaseSize("terra")}
        size={terraSize}
        src={TerraElemento}
        top="80px"
        left="300px"
      />
      <SetasCurvas
        src={curvedArrow}
        top="50px"
        left="70px"
        rotation="rotate(210deg)"
      />
      <SetasCurvas
        src={curvedArrow}
        top="50px"
        left="250px"
        rotation="rotate(170deg)"
      />
      <SetasCurvas
        src={curvedArrow}
        top="205px"
        left="300px"
        rotation="rotate(80deg)"
      />
      <SetasCurvas
        src={curvedArrow}
        top="299px"
        left="170px"
        rotation="rotate(8deg)"
      />
      <SetasCurvas
        src={curvedArrow}
        top="209px"
        left="20px"
        rotation="rotate(-57deg)"
      />
      <SetasRetas
        onClick={() => rotateArrow(1)}
        src={Arrow}
        top="125px"
        left="124px"
        rotation={"rotate(" + rotation1 + "deg)"}
      />
      <SetasRetas
        onClick={() => rotateArrow(2)}
        src={Arrow}
        top="172px"
        left="169px"
        rotation={"rotate(" + (rotation2 + 65) + "deg)"}
      />
      <SetasRetas
        onClick={() => rotateArrow(3)}
        src={Arrow}
        top="195px"
        left="149px"
        rotation={"rotate(" + (rotation3 + 144) + "deg)"}
      />
      <SetasRetas
        onClick={() => rotateArrow(4)}
        src={Arrow}
        top="203px"
        left="108px"
        rotation={"rotate(" + (rotation4 + -144) + "deg)"}
      />
      <SetasRetas
        onClick={() => rotateArrow(5)}
        src={Arrow}
        top="165px"
        left="90px"
        rotation={"rotate(" + (rotation5 + -67) + "deg)"}
      />
      <div style={{ paddingTop: "400px" }}>
        <Form.Field>
          <label>Observação</label>
          <TextArea
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
            maxLength="400"
          />
        </Form.Field>
      </div>
    </>
  );
}
